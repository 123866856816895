import {useEffect, useState} from "react";
import ListPageWithSelection from "../list-page/ListPageWithSelection";
import {packageColumns} from "../../modules/project/plan/config/PackageConfig";
import ListPage from "../list-page/ListPage";

const SelectItemCategory = (props: any) => {
    const [boqDetails, setBoQDetails] = useState([]);
    const [subCategorySelectedRows, setSubCategorySelectedRows] = useState<any>([]);

    const handleCallBack = (rowData: any, buttonData: any) => {
        console.log(rowData);
    }

    const handlePackageItemSelection = (rows: any) => {
        props.handleRowSelection(rows);
    }

    useEffect(() => {
        const selectedDetails: any = [];
        if (props.boq?.boqItems != undefined) {
            props?.boq?.boqItems.forEach((boqItem: any) => {
                if (boqItem.materialSubCategoryId == props.subCategoryId) {
                    boqItem.boqSkus.map((boqSku: any) => {
                        boqSku.materialSubCategoryId = props.subCategoryId;
                        boqSku.itemCategory = boqItem.itemCategory;
                        selectedDetails.push({
                            ...boqSku,
                            country: props.project.country,
                            showUpdatedCharges: props.showUpdatedCharges,
                            categoryCode: props.categoryCode
                        });
                    });
                }
            });
        }
        setBoQDetails(selectedDetails);
    }, [props.subCategoryId, props.boq]);

    useEffect(() => {
        setSubCategorySelectedRows(props.selectedRows)
    }, [props.selectedRows]);

    return (
        <>
            {props.project.status == 'COMPLETED' ? (
                <ListPage
                    rows={boqDetails}
                    columns={packageColumns}
                    page={0}
                    size={100}
                    totalElements={boqDetails.length}
                    sortColumn={"id"}
                    sortDirection={"desc"}
                    handleCallBack={handleCallBack}
                    checkboxSelection={true}
                    hideColumns={{id: false}}
                    handleRowSelected={handlePackageItemSelection}
                    hideFooterPagination={true}
                    enableRowSelection={true}
                    selectionModel={subCategorySelectedRows}
                />
            ) : (
                <ListPageWithSelection
                    rows={boqDetails}
                    columns={packageColumns}
                    page={0}
                    size={100}
                    totalElements={boqDetails.length}
                    sortColumn={"id"}
                    sortDirection={"desc"}
                    handleCallBack={handleCallBack}
                    checkboxSelection={true}
                    hideColumns={{id: false}}
                    handleRowSelected={handlePackageItemSelection}
                    hideFooterPagination={true}
                    enableRowSelection={true}
                    selectionModel={subCategorySelectedRows}
                />
            )}
        </>
    )
}

export default SelectItemCategory;