import {Box, Button, Grid, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {getFormattedDate, getFormattedTime} from "../../../utils/Common";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {MoreVert} from "@mui/icons-material";
import React, {useState, useEffect} from "react";
import {cloneRevision, getEstimationRevisions} from "../plan/tabs/boq-tabs/features/actions";
import {openSnackbar} from "../../../components/common/features/snackbarSlice";
import {
    CLONE_ESTIMATION_REVISION_ERROR,
    CLONE_ESTIMATION_REVISION_SUCCESS,
    ERROR_COLOR,
    SUCCESS_COLOR
} from "../../../constants";
import {useAppDispatch} from "../../../app/hooks";
import {useNavigate} from "react-router-dom";
import EditEstimationRevision from "../../../components/estimation/revision/EditEstimationRevision";
//import EstimationDetails from "./EstimationDetails";
import EstimationDetailsPopup from "./EstimationDetailsPopup";
import EstimationDetailsPopupNew from "./EstimationDetailsPopupNew";

const EstimationRevisionListItem = (props: any) => {
    const {revision} = props;
    const [openEditPopup, setOpenEditPopup] = useState(false);
    const [openDetailsPopup, setOpenDetailsPopup] = useState(false);
    const [hideCreateRevision, setHideCreateRevision] = useState(false);

    const [createdUser, setCreatedUser] = useState(revision.createdBy > 0 ? revision?.createdUser?.name : "System Admin");
    const [lastUpdatedUser, setLastUpdatedUser] = useState(revision.lastUpdatedBy > 0 ? revision?.lastUpdatedUser?.name : "System Admin");
    let status = "Pending Approval";

    if (revision.status == 'APPROVED') {
        status = "Approved"
    } else if (revision.status == 'DISCARDED') {
        status = "Discarded";
    }

    const nestedSpace = 2.25 - props.primarySpace;
    const dispatch: any = useAppDispatch();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleEditName = () => {
        setOpenEditPopup(true);
    }

    const handleEditPopupCallBack = (data: any) => {
        setOpenEditPopup(false);
        if (data.event == 'save')
            props.refreshPage();
    }

    const handeEstimationDetailsCallBack = (data: any) => {
        setOpenDetailsPopup(false);
        if (data.event == 'approve') {
            props.refreshPage();
        }
    }

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewDetails = (e: any, revision: any) => {
        setOpenDetailsPopup(true);
    }

    const handleCloneRevision = async (revision: any) => {
        try {
            const response = await dispatch(cloneRevision(revision.id)).unwrap();
            if (response) {
                dispatch(
                    openSnackbar({
                        message: CLONE_ESTIMATION_REVISION_SUCCESS,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
                dispatch(getEstimationRevisions(props.project.id));
            }
        } catch (err) {
            console.error("Error", err);
            dispatch(
                openSnackbar({
                    message: CLONE_ESTIMATION_REVISION_ERROR,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    }



    useEffect(() => {
        console.log(props.project.stage);
        //props.project.stage =  "PARAMETER";
        if(["DETAILS","PARAMETER", "HEADCOUNT", "ECATALOG"].includes(props.project.stage) && props.project.estimationType  ===  "engine") {
            console.log("Loadong popup");
            setOpenDetailsPopup(true);
            setHideCreateRevision(true);
        }
    }, []);

    return (
        <Box>
            <Grid container alignItems={"stretch"}>
                <Grid item xs={props.primarySpace}></Grid>
                <Grid item xs={0.25} className={"vertically-center-aligned"}
                      sx={{backgroundColor: 'common.black', color: 'common.white', borderStartStartRadius: 10, borderBottomLeftRadius: 10}}>
                    <Box
                        sx={{textAlign: 'center', width: '100%'}}>
                        A{revision.layer}
                    </Box>
                </Grid>
                <Grid item xs={nestedSpace}
                      sx={{justifyContent: 'stretch', borderTop: 1, borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9', py: 0.7, px: 1}}
                      className={"vertically-center-aligned"}>
                    <Box
                        sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                        <Typography component={"span"}>
                            {revision.name}
                        </Typography>
                        <Box sx={{pr: 1}}>
                            <IconButton onClick={handleEditName}>
                                <EditIcon style={{color: '#3854E4'}} fontSize={"small"}/>
                            </IconButton>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={1.5}
                      sx={{justifyContent: 'stretch', borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9', px: 1}}
                      className={"vertically-center-aligned"}>{getFormattedDate(revision?.createdAt)}</Grid>
                <Grid item xs={1.5}
                      sx={{justifyContent: 'stretch', borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9', px: 1}}
                      className={"vertically-center-aligned"}>{createdUser}</Grid>
                <Grid item xs={3}
                      sx={{justifyContent: 'stretch', borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9', px: 1}}
                      className={"vertically-center-aligned"}>
                    <Box
                        sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                        <Typography>
                            {getFormattedDate(revision?.updatedAt)}
                        </Typography>
                        <Typography>
                            {getFormattedTime(revision?.updatedAt)}
                        </Typography>
                        <Box sx={{pr: 0.5}}>
                            By {lastUpdatedUser}
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={1.5}
                      sx={{justifyContent: 'stretch', borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9', px: 1}}
                      className={"vertically-center-aligned"}>
                    <Typography className={"vertically-center-aligned"}
                                sx={{justifyContent: 'stretch', p: 0.5, color: status == 'Approved' ? "#0F9B03" : 'inherit'}}>
                        {status == 'Approved' ?
                            <CheckCircleIcon sx={{fontSize: '20px', pr: 0.5}}/> : ''}
                        {status}
                    </Typography>
                </Grid>
                <Grid item xs={2}
                      sx={{justifyContent: 'stretch', px: 0.5, borderRight: 1, borderBottom: 1, borderColor: '#E9EBF9'}}
                      className={"vertically-center-aligned"}>
                    <Box
                        sx={{display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center'}}>
                        <Button variant={"contained"} onClick={e => handleViewDetails(e, revision)}
                                size={"small"}>
                            View Details
                        </Button>
                        {(!hideCreateRevision && revision.status == 'PENDING' && revision.layer < 3) &&
                            <>
                                <Button onClick={(e) => handleMenu(e)} variant={"text"}
                                        endIcon={<MoreVert/>}>More </Button>
                                <Menu
                                    anchorEl={anchorEl}
                                    id="account-menu"
                                    open={Boolean(anchorEl)}
                                    onClose={handleClose}
                                    onClick={handleClose}
                                    PaperProps={{
                                        elevation: 0,
                                        sx: {
                                            overflow: 'visible',
                                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                            mt: 1.5,
                                            '& .MuiAvatar-root': {
                                                width: 32,
                                                height: 32,
                                                ml: -0.5,
                                                mr: 1,
                                            },
                                            '&::before': {
                                                content: '""',
                                                display: 'block',
                                                position: 'absolute',
                                                top: 0,
                                                right: 14,
                                                width: 10,
                                                height: 10,
                                                bgcolor: 'background.paper',
                                                transform: 'translateY(-50%) rotate(45deg)',
                                                zIndex: 0,
                                            },
                                        },
                                    }}
                                    transformOrigin={{horizontal: 'right', vertical: 'top'}}
                                    anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
                                >
                                    <MenuItem onClick={() => handleCloneRevision(revision)}>
                                        Create New Revision
                                    </MenuItem>
                                </Menu>
                            </>
                        }
                    </Box>
                </Grid>
            </Grid>
            {revision.hasOwnProperty("children") &&
                revision.children.length > 0 &&
                revision.children.map((nestedRevision: any, nestedRevisionIndex: number) =>
                    <EstimationRevisionListItem
                        key={nestedRevisionIndex}
                        revision={nestedRevision}
                        showUpdatedCharges={props.showUpdatedCharges}
                        project={props.project}
                        categories={props.categories}
                        primarySpace={props.primarySpace + 0.25}
                        refreshPage={props.refreshPage}
                        proceedTabChange={props.proceedTabChange}
                    />)}
            {openEditPopup &&
                <EditEstimationRevision revision={revision} openPopup={openEditPopup}
                                        handleEditPopupCallBack={handleEditPopupCallBack}/>
            }
            {openDetailsPopup &&
                <>
                    <EstimationDetailsPopupNew
                        project={props.project}
                        showUpdatedCharges={props.showUpdatedCharges}
                        categories={props.categories}
                        revision={revision}
                        openPopup={openDetailsPopup}
                        handleCallBack={handeEstimationDetailsCallBack}
                        proceedTabChange={props.proceedTabChange}/>
                </>
            }
        </Box>
    )
}
export default EstimationRevisionListItem;