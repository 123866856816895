import {Box, Grid, Typography} from "@mui/material";
import {getPriceInFormatted} from "../../../../utils/Common";

const CatalogItemComparison = (props: any) => {
    console.log(props);
    return (
        <Grid container alignItems={"stretch"}>
            <Grid item xs={2.5}/>
            <Grid item xs={9.5} className={"revision-comparison"}>
                <Box sx={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                    {Object.keys(props.subCategory.revisions).map((revisionId: string, revisionIndex: number) => {
                        return (
                            <Box sx={{
                                width: '430px',
                                marginRight: '20px',
                                display: 'inline-block',
                                height: '100%'
                            }}
                                 key={revisionId + "-sub-category-" + revisionIndex + "-" + props.subCategory.id}>
                                {props.subCategory.revisions[revisionId].map((estimationItemSku: any, estimationItemSkuIndex: number) => {
                                    if (!props.hideCommon || (props.hideCommon && !estimationItemSku.commonValues)) {
                                        return (
                                            <Box key={revisionId + "-sku-" + estimationItemSku.id} sx={{
                                                border: 1,
                                                borderColor: '#E8E8E8',
                                                borderRadius: '4px',
                                                mb: 2,
                                                backgroundColor: '#F8F8F8'
                                            }}>
                                                <Box className={"space-between"}
                                                     sx={{p: 1.5, borderBottom: 1, borderColor: '#E0E0E0'}}>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '12px',
                                                            fontWeight: 500
                                                        }}>{estimationItemSku.sku?.name || "MIS"}</Typography>
                                                    <Typography
                                                        sx={{
                                                            fontSize: '11px',
                                                            fontWeight: 400
                                                        }}>UOM: {estimationItemSku.sku?.uom || "MIS"}</Typography>
                                                </Box>
                                                <Box>
                                                    <Grid container>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 400,
                                                            p: 1
                                                        }}>Basic Rate</Grid>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 400,
                                                            p: 1
                                                        }}>Qty</Grid>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 400,
                                                            p: 1
                                                        }}>Total</Grid>
                                                    </Grid>
                                                    <Grid container>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            p: 1
                                                        }}>{getPriceInFormatted(estimationItemSku.sellingPrice, "")}</Grid>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            p: 1
                                                        }}>{estimationItemSku.totalQuantity}</Grid>
                                                        <Grid item xs={4} sx={{
                                                            color: '#333333',
                                                            fontSize: '12px',
                                                            fontWeight: 500,
                                                            p: 1
                                                        }}>{getPriceInFormatted(estimationItemSku.sellingPrice * estimationItemSku.totalQuantity, "")}</Grid>
                                                    </Grid>
                                                </Box>
                                            </Box>
                                        )
                                    }
                                })}
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
        </Grid>
    )
}

export default CatalogItemComparison;