import {Box, Grid, Typography} from "@mui/material";


const FieldComparison = (props: any) => {
    const {field, index} = props;

    if (!props.hideCommon || (props.hideCommon && !field.commonValues)) {
        return (
            <Grid container alignItems={"stretch"}>
                <Grid item xs={2.5}>
                    <Box sx={{mr: 2, border: 1, borderTop: index == 0 ? 1 : 0, borderColor: 'divider', p: 2, whiteSpace:'break-spaces'}}>
                        <Typography>
                            {field.label}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item xs={9.5} className={"revision-comparison"}>
                    {Object.keys(field.revisions).map((revisionId: string, revisionIndex: number) => {
                        return (
                            <Box sx={{
                                width: '430px',
                                marginRight: '20px',
                                display: 'inline-block',
                                height: '100%',
                                whiteSpace:'break-spaces'
                            }}
                                 key={revisionId + "-field-" + revisionIndex + "-" + field.name}>
                                <Box sx={{
                                    border: 1,
                                    borderTop: index == 0 ? 1 : 0,
                                    borderColor: 'divider',
                                    p: 2,
                                    height: 'inherit'
                                }}>
                                    <Typography>
                                        {field.revisions[revisionId]}
                                    </Typography>
                                </Box>
                            </Box>
                        )
                    })}
                </Grid>
            </Grid>
        )
    } else {
        return <></>
    }
}
export default FieldComparison;