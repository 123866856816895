import {Box, FormControl, Grid, NativeSelect, Typography,} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getPriceInFormatted} from "../../utils/Common";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import {Doughnut} from "react-chartjs-2";
import {getChartOptionsWithinDisplay} from "../../utils/ChartUtils";

const OverallSummary = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [chartData, setChartData] = useState<any>({});
    const [chartOptions, setChartOptions] = useState<any>({});
    const backgroundColors: string[] = [
        "#fbd474",
        "#A74462",
        "#048cdc",
        "#3F3B4E",
        "#fdb67a",
        "#5E33FF",
        "#33FF6B",
        "#FF5733",
    ];
    const [mainText, setMainText] = useState("Overall");
    const [summaryDetails, setSummaryDetails] = useState<any[]>([]);

    const [boqBreakupDetails, setBoqBreakupDetails] = useState<any[]>([]);
    const [cogsCalculationType, setCogsCalculationType] = useState("carpet");
    const [gsvCalculationType, setGsvCalculationType] = useState("carpet");

    const onCogsCalculationChangeHandler = (e: any) => {
        setCogsCalculationType(e.target.value);
    }
    const onGsvCalculationChangeHandler = (e: any) => {
        setGsvCalculationType(e.target.value);
    }

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                props.project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const projectCarpetArea = props.project.carpetArea;
        const projectBuiltUpArea = props.project.projectDetails?.builtUpArea;
        const charges: any[] = [];
        let overAllTotal = 0;
        let overAllTotalCogs = 0;
        let subTotalForTax = 0;

        const updatedChartData: any[] = [];

        let dactotal = props.project.carpetArea * props.project.projectDetails?.designConsultancyRate;

        let prelimTotal = props.project.carpetArea * props.project.projectDetails?.prelimsRate;

        overAllTotal += dactotal;
        overAllTotalCogs += dactotal;
        subTotalForTax += dactotal;
        updatedChartData.push({
            name: "Design and Consultancy Charges",
            total: dactotal,
        });

        let cogsDccTotalPerSqft = dactotal / projectCarpetArea;
        let gsvDccTotalPerSqft = dactotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsDccTotalPerSqft = dactotal / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvDccTotalPerSqft = dactotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'dcc',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Design and Consultancy Charges",
            cogs: dactotal,
            nsv: "NA",
            gsv: dactotal,
            nsvPercentage: 0,
            cogsPercentage: cogsDccTotalPerSqft,
            gsvPercentage: gsvDccTotalPerSqft,
        });

        overAllTotal += prelimTotal;
        overAllTotalCogs += prelimTotal;
        subTotalForTax += prelimTotal;
        updatedChartData.push({name: "Prelims Charges", total: prelimTotal});

        let cogsPrelimTotalPerSqft = prelimTotal / projectCarpetArea;
        let gsvPrelimTotalPerSqft = prelimTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsPrelimTotalPerSqft = prelimTotal / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvPrelimTotalPerSqft = prelimTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'pc',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Prelims Charges",
            nsv: "NA",
            cogs: prelimTotal,
            gsv: prelimTotal,
            nsvPercentage: 0,
            cogsPercentage: cogsPrelimTotalPerSqft,
            gsvPercentage: gsvPrelimTotalPerSqft,
        });

        let workContractTotal = 0;
        let supplyTotal = 0;
        let workContractTotalCogs = 0;
        let supplyTotalCogs = 0;

        if (boqBreakupDetails.length > 0) {
            boqBreakupDetails.map((boqDetails: any) => {
                let boqCategoryTotal = boqDetails.total;
                let boqCategoryTotalCogs = boqDetails.totalCogs;

                if (boqDetails.code.toLowerCase() == "ff") {
                    if (props.showUpdatedCharges) {
                        boqCategoryTotalCogs = boqCategoryTotalCogs + ((boqCategoryTotalCogs * 2) / 100);
                    }
                    supplyTotal += boqCategoryTotal;
                    supplyTotalCogs += boqCategoryTotalCogs;
                } else {
                    if (props.showUpdatedCharges) {
                        boqCategoryTotalCogs = boqCategoryTotalCogs + ((boqCategoryTotalCogs * 6) / 100);
                    }

                    workContractTotal += boqCategoryTotal;
                    workContractTotalCogs += boqCategoryTotalCogs;
                }

                updatedChartData.push({
                    name: boqDetails.name,
                    total: boqCategoryTotal,
                });
                overAllTotal += boqCategoryTotal;
                overAllTotalCogs += boqCategoryTotalCogs;
                subTotalForTax += boqCategoryTotal;
            });
        }

        let cogsWorkContractTotalPerSqft = workContractTotalCogs / projectCarpetArea;
        let gsvWorkContractTotalPerSqft = workContractTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsWorkContractTotalPerSqft = workContractTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvWorkContractTotalPerSqft = workContractTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'wc',
            isCategory: true,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px 16px 0 16px",
            name: "Works Contract",
            nsv: workContractTotal - workContractTotalCogs,
            cogs: workContractTotalCogs,
            gsv: workContractTotal,
            nsvPercentage: ((workContractTotal - workContractTotalCogs) / workContractTotal) * 100,
            cogsPercentage: cogsWorkContractTotalPerSqft,
            gsvPercentage: gsvWorkContractTotalPerSqft,
        });

        let cogsSupplyTotalPerSqft = supplyTotalCogs / projectCarpetArea;
        let gsvSupplyTotalPerSqft = supplyTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsSupplyTotalPerSqft = supplyTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvSupplyTotalPerSqft = supplyTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'supply',
            isCategory: true,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px 16px 0 16px",
            name: "Supply",
            nsv: supplyTotal - supplyTotalCogs,
            cogs: supplyTotalCogs,
            gsv: supplyTotal,
            nsvPercentage: ((supplyTotal - supplyTotalCogs) / supplyTotal) * 100,
            cogsPercentage: cogsSupplyTotalPerSqft,
            gsvPercentage: gsvSupplyTotalPerSqft
        });

        const taxExcludingTotalCogs = dactotal + prelimTotal + workContractTotalCogs + supplyTotalCogs;
        const taxExcludingTotalNsv = (workContractTotal - workContractTotalCogs) + (supplyTotal - supplyTotalCogs);
        const taxExcludingTotalGsv = dactotal + prelimTotal + workContractTotal + supplyTotal;

        let cogsTaxExcludingTotalPerSqft = taxExcludingTotalCogs / projectCarpetArea;
        let gsvTaxExcludingTotalPerSqft = taxExcludingTotalGsv / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTaxExcludingTotalPerSqft = taxExcludingTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTaxExcludingTotalPerSqft = taxExcludingTotalGsv / projectBuiltUpArea;
        }

        charges.push({
            id: 'excluding-tax',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px 16px 0 16px",
            name: "TOTAL COST FOR PROJECT (Excluding taxes)",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs,
            gsv: taxExcludingTotalGsv,
            nsvPercentage: (taxExcludingTotalNsv / taxExcludingTotalGsv) * 100,
            cogsPercentage: cogsTaxExcludingTotalPerSqft,
            gsvPercentage: gsvTaxExcludingTotalPerSqft
        });

        let localAuthorityCharges = 0;
        if (
            props.project.projectDetails?.isAuthorityApprovalCharges &&
            props.project.projectDetails?.authorityApprovalCharges > 0
        ) {
            localAuthorityCharges = props.project.projectDetails?.authorityApprovalCharges;
            overAllTotal += localAuthorityCharges;
            overAllTotalCogs += localAuthorityCharges;
            subTotalForTax += localAuthorityCharges;
            updatedChartData.push({
                name: "Local Authority Charges",
                total: localAuthorityCharges,
            });
        }

        charges.push({
            id: 'local',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Local Authority Charges",
            nsv: "NA",
            cogs: localAuthorityCharges,
            gsv: localAuthorityCharges,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });

        let cogsTotalLocalPerSqft = (taxExcludingTotalCogs + localAuthorityCharges) / projectCarpetArea;
        let gsvTotalLocalPerSqft = (taxExcludingTotalGsv + localAuthorityCharges) / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalLocalPerSqft = (taxExcludingTotalCogs + localAuthorityCharges) / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalLocalPerSqft = (taxExcludingTotalGsv + localAuthorityCharges) / projectBuiltUpArea;
        }

        charges.push({
            id: 'total-local',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Total cost with local authority charges",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs + localAuthorityCharges,
            gsv: taxExcludingTotalGsv + localAuthorityCharges,
            nsvPercentage: (taxExcludingTotalNsv / (taxExcludingTotalGsv + localAuthorityCharges)) * 100,
            cogsPercentage: cogsTotalLocalPerSqft,
            gsvPercentage: gsvTotalLocalPerSqft
        });

        let bocwTotal = 0;
        let bocwName = "BOCW Charges";
        if (
            props.project.projectDetails?.bocwApplicable &&
            props.project.projectDetails?.bocwApplicableRate > 0
        ) {
            bocwName = "BOCW Charges @ " + props.project.projectDetails?.bocwApplicableRate + " %"
            bocwTotal = (overAllTotal * props.project.projectDetails?.bocwApplicableRate) / 100;
            overAllTotal += bocwTotal;
            overAllTotalCogs += bocwTotal;
            updatedChartData.push({
                name:
                    "BOCW Charges @ " +
                    props.project.projectDetails?.bocwApplicableRate +
                    "%",
                total: bocwTotal,
            });
        }

        charges.push({
            id: 'bocw',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: bocwName,
            nsv: "NA",
            cogs: bocwTotal,
            gsv: bocwTotal,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });


        let cogsTotalWithoutTaxPerSqft = (taxExcludingTotalCogs + localAuthorityCharges + bocwTotal) / projectCarpetArea;
        let gsvTotalWithoutTaxPerSqft = (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal) / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalWithoutTaxPerSqft = (taxExcludingTotalCogs + localAuthorityCharges + bocwTotal) / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalWithoutTaxPerSqft = (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal) / projectBuiltUpArea;
        }

        charges.push({
            id: 'total-without-tax',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "TOTAL COST FOR PROJECT WITHOUT TAXES",
            nsv: taxExcludingTotalNsv,
            cogs: taxExcludingTotalCogs + localAuthorityCharges + bocwTotal,
            gsv: taxExcludingTotalGsv + localAuthorityCharges + bocwTotal,
            nsvPercentage: (taxExcludingTotalNsv / (taxExcludingTotalGsv + localAuthorityCharges + bocwTotal)) * 100,
            cogsPercentage: cogsTotalWithoutTaxPerSqft,
            gsvPercentage: gsvTotalWithoutTaxPerSqft
        });

        let taxTotal = ((taxExcludingTotalGsv + localAuthorityCharges + bocwTotal) * 18) / 100;
        let taxTotalCogs = ((taxExcludingTotalCogs + localAuthorityCharges + bocwTotal) * 18) / 100;
        overAllTotal += taxTotal;
        overAllTotalCogs += taxTotalCogs;
        updatedChartData.push({name: "Total Taxes @ 18%", total: taxTotal});

        charges.push({
            id: 'taxes',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Taxes @ 18 %",
            nsv: "NA",
            cogs: taxTotalCogs,
            gsv: taxTotal,
            nsvPercentage: 0,
            cogsPercentage: 0,
            gsvPercentage: 0
        });

        let cogsTotalPerSqft = overAllTotalCogs / projectCarpetArea;
        let gsvTotalPerSqft = overAllTotal / projectCarpetArea;

        if (cogsCalculationType == 'built') {
            cogsTotalPerSqft = overAllTotalCogs / projectBuiltUpArea;
        }
        if (gsvCalculationType == 'built') {
            gsvTotalPerSqft = overAllTotal / projectBuiltUpArea;
        }

        charges.push({
            id: 'total',
            isCategory: false,
            backgroundColor: "info.contrastText",
            padding: "12px",
            margin: "16px",
            name: "Total Cost Of Projects With Taxes",
            nsv: overAllTotal - overAllTotalCogs,
            cogs: overAllTotalCogs,
            gsv: overAllTotal,
            nsvPercentage: ((overAllTotal - overAllTotalCogs) / overAllTotal) * 100,
            cogsPercentage: cogsTotalPerSqft,
            gsvPercentage: gsvTotalPerSqft
        });

        setChartData({
            labels: updatedChartData.map((chartData: any) => chartData.name),
            datasets: [
                {
                    data: updatedChartData.map((chartData: any) =>
                        (
                            ((chartData.total as number) / (overAllTotal as number)) *
                            100
                        ).toFixed(1)
                    ),
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 0.2,
                },
            ],
        });
        setSummaryDetails(charges);
    }, [boqBreakupDetails, props.project, cogsCalculationType, gsvCalculationType]);

    useEffect(() => {
        setChartOptions(getChartOptionsWithinDisplay());
    }, [chartData]);

    useEffect(() => {
        if (props.categories.length > 0) {
            const updatedCategories = props.categories
                .map((category: any) => {
                    let estimationTotal: number = 0;
                    let estimationTotalCogs: number = 0;
                    const subCategories = category.subCategories
                        .map((subCategory: any) => {
                            const estimation = props.estimations?.find(
                                (estimation: any) =>
                                    estimation.materialSubCategoryId === subCategory.id
                            );
                            if (estimation && estimation.estimationItems.length > 0) {
                                let subCategoryTotalCogs: number = 0;
                                let subCategoryTotal: number = 0;
                                const updatedEstimations = estimation.estimationItems.map(
                                    (estimationItem: any) => ({
                                        ...estimationItem,
                                        estimationItemSkus:
                                            estimationItem.estimationItemSkus.length > 0 &&
                                            estimationItem.estimationItemSkus.map((sku: any) => {
                                                subCategoryTotalCogs = subCategoryTotalCogs + (parseInt(sku.totalQuantity) * parseFloat(sku.targetPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.cogsDomainChargesPerUnit?.total));
                                                subCategoryTotal = subCategoryTotal + (parseInt(sku.totalQuantity) * parseFloat(sku.sellingPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.gsvDomainChargesPerUnit?.total));
                                                return sku;
                                            }),
                                    })
                                );

                                estimationTotal = estimationTotal + subCategoryTotal;
                                estimationTotalCogs =
                                    estimationTotalCogs + subCategoryTotalCogs;

                                return {
                                    ...subCategory,
                                    total: subCategoryTotal,
                                    totalCogs: subCategoryTotalCogs,
                                    estimationItems: updatedEstimations,
                                };
                            }
                            return null;
                        })
                        .filter((subCategory: any) => subCategory !== null);

                    if (subCategories.length > 0) {
                        return {
                            ...category,
                            total: estimationTotal,
                            totalCogs: estimationTotalCogs,
                            subCategories,
                        };
                    }

                    return null;
                })
                .filter((category: any) => category !== null);

            setBoqBreakupDetails(updatedCategories);
            if (props.project.projectDetails == null) {
                loadProjectDetails();
            }
        }
    }, [props.estimations, props.categories]);

    if (isLoading) {
        return <Loader/>;
    }
    return (
        <Grid container spacing={2} alignItems={"flex-start"} className={"overall-summary"}>
            <Grid item xs={4}>
                {Object.keys(chartData).length > 0 && (
                    <Box
                        className="chart-container"
                        sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid lightgray",
                            borderRadius: "2px",
                            padding: "32px",
                            m: 0,
                            height: "95%",
                        }}
                    >
                        <Typography
                            style={{
                                textAlign: "center",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                            }}
                        >
                            <Typography
                                component={"span"}
                                sx={{
                                    fontSize: "20px",
                                    fontWeight: 600,
                                    display: "inline-block",
                                    wordWrap: "break-word",
                                }}
                            >
                                {mainText}
                            </Typography>
                        </Typography>
                        <Doughnut
                            id={"sm-doughnut-chart-project"}
                            data={chartData}
                            options={chartOptions}
                        />
                    </Box>
                )}
            </Grid>

            <Grid item xs sx={{height: "80%"}}>
                <Box>
                    <Grid container>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={!props.showUpdatedCharges ? 2 : 4}/>
                                {!props.showUpdatedCharges &&
                                    <Grid item xs={3.3}
                                          sx={{border: 1, borderColor: '#E9EBF9', borderBottom: 0, borderRight: 0}}>
                                        <Typography sx={{textAlign: 'center', fontWeight: 600, py: 2}}>
                                            COGS
                                        </Typography>
                                    </Grid>
                                }
                                <Grid item xs={!props.showUpdatedCharges ? 3.3 : 4}
                                      sx={{borderTop: 1, borderColor: '#E9EBF9', backgroundColor: '#E9EBF9'}}>
                                    <Typography sx={{textAlign: 'center', fontWeight: 600, py: 2}}>
                                        GSV
                                    </Typography>
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 3.3 : 4}
                                      sx={{border: 1, borderColor: '#E9EBF9', borderBottom: 0, borderLeft: 0}}>
                                    <Typography sx={{textAlign: 'center', fontWeight: 600, py: 2}}>
                                        NSV
                                    </Typography>
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 2 : 4}/>
                                {!props.showUpdatedCharges &&
                                    <>
                                        <Grid item xs={1.65}
                                              sx={{
                                                  py: 1,
                                                  px: 0.5,
                                                  borderLeft: 1,
                                                  borderBottom: 1,
                                                  borderColor: '#E9EBF9',
                                                  backgroundColor: '#E9EBF9'
                                              }}
                                              className={"vertically-center-aligned"}>
                                            Amount
                                        </Grid>
                                        <Grid item xs={1.65}
                                              sx={{
                                                  py: 1,
                                                  px: 0.5,
                                                  borderBottom: 1,
                                                  borderColor: '#E9EBF9',
                                                  backgroundColor: '#E9EBF9'
                                              }}>
                                            <Typography>Rate / Sq Ft.</Typography>
                                            <FormControl fullWidth>
                                                <NativeSelect
                                                    onChange={(event: any) => onCogsCalculationChangeHandler(event)}
                                                    defaultValue={cogsCalculationType}
                                                    inputProps={{
                                                        name: 'age',
                                                        id: 'uncontrolled-native',
                                                    }}
                                                    sx={{
                                                        borderBottom: '0', color: 'blue',
                                                        '& .MuiSvgIcon-root': {
                                                            color: 'blue'
                                                        },
                                                        '&:before': {
                                                            border: 'none'
                                                        },
                                                        '&:after': {
                                                            border: 'none'
                                                        },
                                                        '&:not(.Mui-disabled):hover::before': {
                                                            border: 'none'
                                                        },
                                                    }}
                                                >
                                                    <option value={"carpet"}>Carpet Area</option>
                                                    <option value={"built"}>Built Up Area</option>
                                                </NativeSelect>
                                            </FormControl>
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                      sx={{
                                          py: 1,
                                          px: 0.5,
                                          borderBottom: 1,
                                          borderColor: '#E9EBF9',
                                          backgroundColor: '#E9EBF9'
                                      }}
                                      className={"vertically-center-aligned"}>
                                    Amount
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                      sx={{
                                          py: 1,
                                          px: 0.5,
                                          borderBottom: 1,
                                          borderColor: '#E9EBF9',
                                          backgroundColor: '#E9EBF9'
                                      }}>
                                    <Typography>Rate / Sq Ft.</Typography>
                                    <FormControl fullWidth>
                                        <NativeSelect
                                            onChange={(event: any) => onGsvCalculationChangeHandler(event)}
                                            defaultValue={gsvCalculationType}
                                            className={"gsv-select"}
                                            inputProps={{
                                                name: 'age',
                                                id: 'uncontrolled-native',
                                            }}
                                            sx={{
                                                p: 0,
                                                borderBottom: '0', color: 'blue',
                                                '& .MuiSvgIcon-root': {
                                                    color: 'blue'
                                                },
                                                '&:before': {
                                                    border: 'none'
                                                },
                                                '&:after': {
                                                    border: 'none'
                                                },
                                                '&:not(.Mui-disabled):hover::before': {
                                                    border: 'none'
                                                },
                                            }}
                                        >
                                            <option value={"carpet"}>Carpet Area</option>
                                            <option value={"built"}>Built Up Area</option>
                                        </NativeSelect>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                      sx={{
                                          py: 1,
                                          px: 0.5,
                                          borderBottom: 1,
                                          borderColor: '#E9EBF9',
                                          backgroundColor: '#E9EBF9'
                                      }}
                                      className={"vertically-center-aligned"}>
                                    Amount
                                </Grid>
                                <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                      sx={{
                                          py: 1,
                                          px: 0.5,
                                          textAlign: 'center',
                                          borderBottom: 1,
                                          borderRight: 1,
                                          borderColor: '#E9EBF9',
                                          backgroundColor: '#E9EBF9',
                                          justifyContent: 'center'
                                      }}
                                      className={"vertically-center-aligned"}>
                                    %Age
                                </Grid>
                            </Grid>
                        </Grid>
                        {summaryDetails.map((summaryDetail: any, summaryIndex: number) => {
                            return (
                                <Grid item xs={12} key={"summary-charges-" + summaryIndex}>
                                    <Grid container alignItems={"stretch"}
                                          className={"vertically-center-aligned"}>
                                        <Grid item xs={!props.showUpdatedCharges ? 5.3 : 4} sx={{p: 0.5}}/>
                                        <Grid item xs={!props.showUpdatedCharges ? 3.3 : 4}
                                              sx={{p: 0.5, backgroundColor: '#E9EBF9'}}/>
                                        <Grid item xs={!props.showUpdatedCharges ? 3.3 : 4} sx={{p: 0.5}}/>
                                        <Grid item xs={!props.showUpdatedCharges ? 2 : 4}
                                              sx={{backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'}}>
                                            <Typography
                                                sx={{
                                                    fontSize: "12px",
                                                    fontWeight: 600,
                                                    textAlign: "start",
                                                    py: 1,
                                                    px: 0.5,
                                                    height: '100%'
                                                }}>
                                                {summaryDetail.name}
                                            </Typography>
                                        </Grid>
                                        {!props.showUpdatedCharges &&
                                            <>
                                                <Grid item xs={1.65}
                                                      sx={{backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'}}>
                                                    <Typography sx={{p: 1, height: '100%', fontSize: '12px'}}
                                                                className={"vertically-center-aligned"}>
                                                        {getPriceInFormatted(summaryDetail.cogs, props.project.country)}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={1.65}
                                                      sx={{
                                                          textAlign: 'center',
                                                          backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'
                                                      }}>
                                                    <Typography sx={{p: 1, height: '100%', fontSize: '12px'}}
                                                                className={"vertically-center-aligned"}>
                                                        {getPriceInFormatted(Number.parseFloat(summaryDetail.cogsPercentage), props.project.country)}
                                                    </Typography>
                                                </Grid>
                                            </>
                                        }
                                        <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                              sx={{backgroundColor: '#E9EBF9'}}>
                                            <Typography sx={{py: 1, height: '100%', px: 1, fontSize: '12px'}}
                                                        className={"vertically-center-aligned"}>
                                                {getPriceInFormatted(summaryDetail.gsv, props.project.country)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                              sx={{textAlign: 'center', backgroundColor: '#E9EBF9'}}>
                                            <Typography sx={{py: 1, height: '100%', px: 1, fontSize: '12px'}}
                                                        className={"vertically-center-aligned"}>
                                                {getPriceInFormatted(Number.parseFloat(summaryDetail.gsvPercentage), props.project.country)}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                              sx={{backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'}}>
                                            <Typography sx={{p: 1, height: '100%', fontSize: '12px'}}
                                                        className={"vertically-center-aligned"}>
                                                {!isNaN(summaryDetail.nsv) ? getPriceInFormatted(summaryDetail.nsv, props.project.country) : summaryDetail.nsv}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={!props.showUpdatedCharges ? 1.65 : 2}
                                              sx={{
                                                  textAlign: 'center',
                                                  backgroundColor: summaryDetail.id == 'total' ? '#E9EBF9' : '#F8F8F8'
                                              }}>
                                            <Typography
                                                sx={{p: 1, height: '100%', fontSize: '12px', justifyContent: 'center'}}
                                                className={"vertically-center-aligned"}>
                                                {!isNaN(summaryDetail.nsvPercentage) ? Number.parseFloat(summaryDetail.nsvPercentage).toFixed(2) : Number.parseFloat("0").toFixed(2)}%
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </Grid>
                </Box>
            </Grid>
        </Grid>
    );
};

export default OverallSummary;
