import { createSlice } from '@reduxjs/toolkit';
import {jwtDecode} from 'jwt-decode';
import { getItem, setItem } from '../utils/LocalStorage';

const initialState = {
  token: getItem('auth') || null,
  user: getItem('user_name') || null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAuth: (state, action) => {
      const { token } = action.payload;
      const userDetails = token && jwtDecode(token);
      state.token = token;
      state.user = userDetails?.userName || null;
      setItem('auth', token);
      setItem('user_name', userDetails?.userName || null);
      setItem("beta_enabled", userDetails?.betaEnabled || false);
    },
    clearAuth: (state) => {
      state.token = null;
      state.user = null;
      setItem('auth', null);
      setItem('user_name', null);
    },
  },
});

export const { setAuth, clearAuth } = authSlice.actions;

export default authSlice.reducer;
