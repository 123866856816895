import {Box, Grid, Typography} from "@mui/material";

const ProjectRoomDetails = (props: any) => {

    return (
        <>
            <Box sx={{overflowX: 'auto', whiteSpace: 'nowrap'}}>
                {props.headCountDetails.items.map((headCountItem: any, index: number) => (
                    (!props.hideCommon || (props.hideCommon && !headCountItem.commonValues)) && (
                        <Box sx={{mb: 2}} key={"project-room-index-" + index}>
                            <Grid container alignItems={"stretch"}>
                                <Grid item xs={2.5}>
                                    <Box sx={{
                                        mr: 2,
                                        border: 1,
                                        borderColor: 'divider',
                                        p: 2,
                                        backgroundColor: '#E9EBF9',
                                        whiteSpace:'break-spaces'
                                    }}>
                                        <Typography>
                                            {headCountItem.name}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item xs={9.5} className={"revision-comparison"}>
                                    {Object.keys(headCountItem.revisions).map((revisionId: string, revisionIndex: number) => (
                                        <Box sx={{
                                            width: '430px',
                                            marginRight: '20px',
                                            display: 'inline-block',
                                            height: '100%'
                                        }}
                                             key={revisionId + "-field-" + revisionIndex + "-" + headCountItem.id}>
                                            <Box sx={{
                                                border: 1,
                                                borderColor: 'divider',
                                                backgroundColor: '#F9F9F9',
                                                p: 2,
                                                height: 'inherit'
                                            }}>
                                                <Grid container>
                                                    <Grid item xs>Quantity</Grid>
                                                    <Grid item xs>Finish</Grid>
                                                </Grid>
                                            </Box>
                                        </Box>
                                    ))}
                                </Grid>
                            </Grid>
                            {headCountItem.skus.map((itemSku: any, itemSkuIndex: number) => {
                                if (!props.hideCommon || (props.hideCommon && !itemSku.commonValues)) {
                                    return (
                                        <Box
                                            key={props.headCountDetails.id + "-project-room-sku" + itemSkuIndex + "-" + headCountItem.id}>
                                            <Grid container alignItems={"stretch"}>
                                                <Grid item xs={2.5}>
                                                    <Box sx={{
                                                        mr: 2,
                                                        border: 1,
                                                        borderTop: 0,
                                                        borderColor: 'divider',
                                                        p: 2,
                                                        whiteSpace:'break-spaces'
                                                    }}>
                                                        <Typography>
                                                            {itemSku.name}
                                                        </Typography>
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={9.5} className={"revision-comparison"}>
                                                    {Object.keys(itemSku.revisions).map((revisionId: string, revisionIndex: number) => (
                                                        <Box sx={{
                                                            width: '430px',
                                                            marginRight: '20px',
                                                            display: 'inline-block',
                                                            height: '100%'
                                                        }}
                                                             key={revisionId + "-field-sku" + revisionIndex + "-" + itemSku.id}>
                                                            <Box sx={{
                                                                border: 1,
                                                                borderTop: 0,
                                                                borderColor: 'divider',
                                                                p: 2,
                                                                height: 'inherit'
                                                            }}>
                                                                <Grid container>
                                                                    <Grid item
                                                                          xs>{itemSku.revisions[revisionId].quantity}</Grid>
                                                                    <Grid item xs>
                                                                        <Typography sx={{textTransform: 'capitalize'}}>
                                                                            {itemSku.revisions[revisionId].roomType.replace("-", " ")}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Box>
                                                        </Box>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    )
                                }
                            })}
                        </Box>
                    )
                ))}
            </Box>
        </>
    )
}

export default ProjectRoomDetails;