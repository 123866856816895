import Loader from "../../../Loader";
import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, Grid, Typography} from "@mui/material";
import {config} from "../../../head-count/RoomDataConfig";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ProjectRoomDetails from "./ProjectRoomDetails";

const ProjectRoomsComparison = (props: any) => {
    const [expandHeadCount, setExpandHeadCount] = useState<any>({});
    const [hideCommon, setHideCommon] = useState(true);
    const roomCountConfig = config;

    const handleExpand = (headCountName: any) => {
        const newExpandHeadCount = {...expandHeadCount, [headCountName]: !expandHeadCount[headCountName]};
        setExpandHeadCount(newExpandHeadCount);
    }

    const handleHideCommon = () => {
        setHideCommon(!hideCommon);
    }

    const allEqual = (arr: any[]) => arr.every(v => v.quantity === arr[0].quantity && v.roomType == arr[0].roomType)

    useEffect(() => {
        roomCountConfig.map((roomConfig: any) => {
            roomConfig.commonValues = true;
            roomConfig.items.map((roomItem: any) => {
                let itemFieldDetails: any = {};
                roomItem.commonValues = true;
                roomItem.skus.map((sku: any) => {
                    let allFieldValues: any[] = [];
                    let fieldDetails: any = {};
                    props.revisionProjectRooms.map((revisionProjectRoom: any) => {
                        revisionProjectRoom.projectRooms.map((projectRoom: any) => {
                            projectRoom.details.map((projectRoomItem: any) => {
                                if (roomItem.id == projectRoomItem.itemId && sku.id == projectRoomItem.skuId) {
                                    fieldDetails[revisionProjectRoom.id] = projectRoomItem;
                                    itemFieldDetails[revisionProjectRoom.id] = 1;
                                    allFieldValues.push(projectRoomItem);
                                }
                            });
                        });
                    });
                    sku.revisions = fieldDetails;
                    sku.commonValues = true;
                    if (!allEqual(allFieldValues)) {
                        sku.commonValues = false;
                        roomConfig.commonValues = false;
                        roomItem.commonValues = false;
                    }
                });
                roomItem.revisions = itemFieldDetails;
            });
        });

    }, [props.revisionProjectRooms]);

    useEffect(() => {
        let firstRoom: string = "";
        roomCountConfig.map((roomDetails: any) => {
            if (hideCommon) {
                if (firstRoom == "" && !roomDetails.commonValues) {
                    firstRoom = roomDetails.id;
                }
            } else {
                if (firstRoom == "") {
                    firstRoom = roomDetails.id;
                }
            }
        });

        const newExpandHeadCount = {...expandHeadCount, [firstRoom]: true};
        setExpandHeadCount(newExpandHeadCount);
    }, [hideCommon, roomCountConfig]);

    if (props.isHeadCountLoading) {
        return <Loader/>
    }
    return (
        <Box sx={{pb: 8}}>
            <Box className={"space-between vertically-center-aligned"}
                 sx={{border: 1, borderColor: '#333333', borderRadius: '4px', px: 2}}>
                <Typography variant={"h3"} sx={{fontSize: '18px', fontWeight: 600}}>HC Comparison</Typography>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"info"}
                                checked={hideCommon}
                                onChange={handleHideCommon}
                                sx={{
                                    color: '#0486FF',
                                    '&.Mui-checked': {
                                        color: "#0486FF",
                                    },
                                }}
                            />
                        }
                        label="Hide Common Details"
                    />

                </Box>
            </Box>
            <Box sx={{py: 2, px: 4}}>
                {roomCountConfig.map((headCountDetails: any, headCountIndex: number) => {
                    if (!hideCommon || (hideCommon && !headCountDetails.commonValues)) {
                        return (
                            <Box key={props.category + "-head-count-comparison-" + headCountIndex}>
                                <Grid className={"vertically-center-aligned"} container onClick={() => handleExpand(headCountDetails.id)}
                                      sx={{p: 2, backgroundColor: "#E9EBF9", cursor: 'pointer'}}>
                                    <Grid item xs={10}>
                                        <Typography sx={{fontWeight: 700}}>{headCountDetails.name}</Typography>
                                    </Grid>
                                    <Grid item xs={2} sx={{textAlign: 'right'}}>
                                        <Button sx={{py: 0}}>
                                            {expandHeadCount[headCountDetails.id] ? (
                                                <KeyboardArrowUpIcon/>
                                            ) : (
                                                <ExpandMoreIcon/>
                                            )}
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Box sx={{py: 2}}>
                                    {expandHeadCount[headCountDetails.id] &&
                                        <ProjectRoomDetails headCountDetails={headCountDetails}
                                                            hideCommon={hideCommon}/>
                                    }
                                </Box>
                            </Box>
                        )
                    }
                })}
            </Box>
        </Box>
    )
}

export default ProjectRoomsComparison;