import {useEffect, useState} from "react";
import ListPage from "../list-page/ListPage";
import {packageColumns} from "../../modules/project/plan/config/PackageConfig";
import ListPageWithSelection from "../list-page/ListPageWithSelection";
import { Box } from "@mui/material";

const SelectAllPackageItems = (props: any) => {
    const [boqDetails, setBoQDetails] = useState([]);
    const [allSelectedRows, setAllSelectedRows] = useState<any>([]);

    const handleCallBack = (rowData: any, buttonData: any) => {
        console.log(rowData);
    }

    const handlePackageItemSelection = (rows: any) => {
        props.handleAllTabRowSelection(rows);
    }

    useEffect(() => {
        const selectedDetails: any = [];
        if (props.boq?.boqItems != undefined) {
            props?.boq?.boqItems.forEach((boqItem: any) => {
                let categoryCode = "";
                props.categories.map((category: any) => {
                    category.subCategories.map((subCategory: any) => {
                        if (subCategory.id == boqItem.materialSubCategoryId) {
                            categoryCode = category.code;
                        }
                    });
                });
                boqItem.boqSkus.map((boqSku: any) => {
                    boqSku.materialSubCategoryId = boqItem.materialSubCategoryId;
                    boqSku.itemCategory = boqItem.itemCategory;
                    selectedDetails.push({
                        ...boqSku,
                        country: props.project.country,
                        showUpdatedCharges: props.showUpdatedCharges,
                        categoryCode: categoryCode
                    });
                });
            });
        }

        setBoQDetails(selectedDetails);
    }, [props.boq]);

    useEffect(() => {
        let allRows:any[] = [];
        if(Object.keys(props.selectedRows).length > 0) {
            for (let key in props.selectedRows) {
                allRows = [...allRows, ...props.selectedRows[key]];
            }
        }
        setAllSelectedRows(allRows);
    }, [props.selectedRows]);

    return (
        <Box sx={{py: 2}}>
            {props.project.status == 'COMPLETED' ? (
                <ListPage
                    rows={boqDetails}
                    columns={packageColumns}
                    page={0}
                    size={100}
                    totalElements={boqDetails.length}
                    sortColumn={"id"}
                    sortDirection={"desc"}
                    handleCallBack={handleCallBack}
                    checkboxSelection={true}
                    hideColumns={{id: false}}
                    handleRowSelected={handlePackageItemSelection}
                    hideFooterPagination={true}
                    enableRowSelection={true}
                    selectionModel={allSelectedRows}
                />
            ) : (
                <ListPageWithSelection
                    rows={boqDetails}
                    columns={packageColumns}
                    page={0}
                    size={100}
                    totalElements={boqDetails.length}
                    sortColumn={"id"}
                    sortDirection={"desc"}
                    handleCallBack={handleCallBack}
                    checkboxSelection={true}
                    hideColumns={{id: false}}
                    handleRowSelected={handlePackageItemSelection}
                    hideFooterPagination={true}
                    enableRowSelection={true}
                    selectionModel={allSelectedRows}
                />
            )}
        </Box>
    )
}

export default SelectAllPackageItems;