import {Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Typography} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {getPriceInFormatted} from "../../utils/Common";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import * as React from "react";
import {useState} from "react";

const ListItemCategoryAndItems = (props: any) => {
    const [openConfirmBox, setOpenConfirmBox] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const handleItemBoqShow= () => {
        props.handleShowBoqItems();
    }
    const handleDeleteItem = (item: any) => {
        setSelectedItem(item);
        setOpenConfirmBox(true);
    }

    const confirmDeletedItem = () => {
        props.deleteItem(selectedItem);
        handleClose();
    }

    const handleClose = () => {
        setSelectedItem({})
        setOpenConfirmBox(false);
    }
    let counter = 0;
    return (
        <>
            {props.showNoItemsSelected && props.showBoqItems ? (
                <Box sx={{minHeight: '200px', width: '100%'}} className={"center-aligned vertically-center-aligned"}>
                    <Box sx={{width: 'inherit', textAlign: 'center'}}>
                        <Box sx={{p: 1}}>
                            <Typography sx={{color: '#A29F9F', fontSize: '14px'}}>0 items added</Typography>
                        </Box>
                        <Box sx={{p: 1}}>
                            <Typography sx={{color: '#333333', fontSize: '14px'}}>
                                Showing only items selected for BOQ
                            </Typography>
                        </Box>
                        <Box sx={{p: 1}}>
                            <Button variant={"contained"} sx={{fontSize: '12px'}} onClick={handleItemBoqShow}>Show All Items</Button>
                        </Box>
                    </Box>
                </Box>
            ) : (
                <>
                    {props.itemCategories.map((itemCategory: any, itemCategoryIndex: number) => {
                        if (props.showBoqItems && !itemCategory.isItemSelected) {
                            return;
                        }
                        return (
                            <Box key={'item-category-' + itemCategoryIndex}>
                                {itemCategory.items.map((item: any, itemIndex: number) => {
                                    if (props.showBoqItems && item?.totalSelectedSkus == undefined) {
                                        return;
                                    }
                                    let oddEvenClass = "";
                                    const lastCount = counter;
                                    if (counter % 2 === 0) {
                                        oddEvenClass = 'even'
                                    }
                                    counter++;

                                    return (
                                        <Grid
                                            justifyContent={"stretch"}
                                            alignItems={"stretch"}
                                            container
                                            key={`item-category-id-${item.id}-${itemIndex}`}
                                            className={item?.totalSelectedSkus > 0 ? 'selected-item' : ''}
                                        >
                                            <Grid item xs={2}>
                                                <Box
                                                    className={oddEvenClass}
                                                    sx={{
                                                        height: "100%",
                                                        border: 1,
                                                        borderColor: "info.light",
                                                        borderTop: lastCount === 0 ? 1 : 0,
                                                        borderTopColor: "info.light",
                                                    }}
                                                >
                                                    <Box sx={{height: "inherit", py: 4, px: 2}}>
                                                        <Typography
                                                            sx={{
                                                                fontWeight: 700,
                                                                fontSize: "0.9rem",
                                                                pb: 0.7
                                                            }}>{itemCategory.name}</Typography>
                                                        <Typography sx={{fontSize: "0.8rem"}}>{item.name}</Typography>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={8}>
                                                <Box
                                                    className={"center-aligned"}
                                                    sx={{height: "100%", px: 4}}
                                                >
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={4}>
                                                            <InputLabel
                                                                id="demo-simple-select-helper-label"
                                                                sx={{pb: 1, fontSize: "0.8rem"}}
                                                            >
                                                                UOM
                                                            </InputLabel>
                                                            <Box>
                                                                <FormControl>
                                                                    <Select
                                                                        size={"small"}
                                                                        disabled={true}
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        defaultValue={item?.uom}
                                                                        sx={{fontSize: "0.8rem"}}
                                                                    >
                                                                        <MenuItem value={item?.uom}>
                                                                            {item?.uom}
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <InputLabel
                                                                id="demo-simple-select-helper-label"
                                                                sx={{pb: 1, fontSize: "0.8rem"}}
                                                            >
                                                                Select SKU (<Typography component={"span"}
                                                                                        sx={{
                                                                                            color: 'primary.dark',
                                                                                            fontSize: 'inherit'
                                                                                        }}>{item?.totalSkus} available</Typography>)
                                                            </InputLabel>
                                                            <Box>
                                                                <Button
                                                                    onClick={() => props.handleSkuSelection(item, itemCategory.id)}
                                                                    variant={"outlined"}
                                                                    sx={{px: 0.8, py: 0.8, fontSize: "0.8rem"}}
                                                                    endIcon={<ArrowForwardIosIcon/>}
                                                                >
                                                                    {((item.hasOwnProperty('totalSelectedSkus') && item.totalSelectedSkus > 0)) ? item?.totalSelectedSkus + ' Skus Selected' : 'Select Skus'}
                                                                </Button>
                                                            </Box>
                                                        </Grid>
                                                        <Grid item xs={4}>
                                                            <InputLabel
                                                                id="demo-simple-select-helper-label"
                                                                sx={{pb: 1, fontSize: "0.8rem"}}
                                                            >
                                                                Quantity
                                                            </InputLabel>
                                                            <Box>
                                                                <TextField
                                                                    fullWidth
                                                                    size={"small"}
                                                                    disabled={true}
                                                                    type={"number"}
                                                                    variant={"outlined"}
                                                                    className={"inputBox"}
                                                                    value={item?.totalQuantity}
                                                                    InputProps={{
                                                                        style: {
                                                                            fontSize: "0.8rem",
                                                                            borderRadius: "5px",
                                                                            borderColor: "primary.light",
                                                                        },
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={2}>
                                                <Box className={"center-aligned"} sx={{height: "100%"}}>
                                                    <Grid container>
                                                        <Grid item xs={10}>
                                                            <Typography sx={{fontSize: "0.8rem"}}>Total</Typography>
                                                            <Typography sx={{fontSize: "0.7rem"}}>
                                                                {getPriceInFormatted(item?.total, props.project.country)}
                                                            </Typography>
                                                        </Grid>
                                                        {(item?.totalSelectedSkus > 0 && props.project.status == 'CREATED') &&
                                                            <Grid item xs={2}>
                                                                <Box className={"center-aligned"}
                                                                     sx={{cursor: "pointer"}}>
                                                                    <DeleteOutlineIcon
                                                                        onClick={() => handleDeleteItem(item)}/>
                                                                </Box>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Box>
                        )
                    })}
                </>
            )}
            {openConfirmBox &&
                <Dialog onClose={handleClose} open={openConfirmBox} sx={{marginRight: '0'}}>
                    <Box sx={{padding: '20px 30px', textAlign: 'center'}}>
                        <DialogTitle sx={{
                            fontSize: '16px',
                            fontWeight: '400',
                            letterSpacing: "0.8px"
                        }}>{props?.confirmText ? props.confirmText : "Are you sure you want to delete Item and its selected Skus from Estimation?"}</DialogTitle>
                        <Box sx={{margin: '0 auto'}}>
                            <Button variant={"contained"} sx={{mr: 2}} onClick={() => confirmDeletedItem()}>OK</Button>
                            <Button variant={"outlined"} type="submit" onClick={() => handleClose()}>Cancel</Button>
                        </Box>
                    </Box>
                </Dialog>
            }
        </>
    )
}
export default ListItemCategoryAndItems;