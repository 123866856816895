import {useState} from "react";
import {Box, Typography} from "@mui/material";

const styles = {
    button: {
        fontWeight: 700, cursor: 'pointer', color: 'primary.main'
    }
}
const ReadMore = (props: any) => {
    const {className, text, maxChars} = props;
    const readMoreText = "MORE";
    const readLessText = "LESS";
    const [isExpanded, setIsExpanded] = useState(false);
    const hasReadMore = text != null ? text.length > maxChars : false;

    return (
        <Box className={className}>
            {text && (isExpanded || !hasReadMore) && (
                <Typography>
                    {text}{" "}
                    {hasReadMore && !!isExpanded && (
                        <Typography sx={styles.button} component={"span"}
                                    onClick={() => setIsExpanded(false)}>
                            {readLessText}
                        </Typography>
                    )}
                </Typography>
            )}
            {text && !isExpanded && !!hasReadMore && (
                <Typography>
                    {text.slice(0, maxChars)}...{" "}
                    <Typography className={"more-button"} sx={styles.button} component={"span"}
                                onClick={() => setIsExpanded(true)}>
                        {readMoreText}
                    </Typography>
                </Typography>
            )}
        </Box>
    )
}

export default ReadMore;