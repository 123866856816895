import { Box, Grid, Typography } from "@mui/material";
import BuildForm from "../input-form/BuildForm";
import { useEffect, useState } from "react";
import { api } from "../../utils/Axios";
import HeadCountRoomData from "./HeadCountRoomData";
import { openSnackbar } from "../common/features/snackbarSlice";
import { ERROR_COLOR, SUCCESS_COLOR } from "../../constants";
import { useAppDispatch } from "../../app/hooks";
import Loader from "../Loader";

const HeadCountTab = (props: any) => {
    const dispatch = useAppDispatch();
    const { project } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [formFields, setFormFields] = useState<any>(officeDetailsFormFields);
    const [roomItemCategegories, setRoomItemCategegories] = useState<any[]>([]);

    const getProjectRoomData = () => {
        api.get(`/procurement/project/get-room-count-details/all/${props.project.id}?revisionId=${props?.revision?.id}`).then((response) => {
            if (response.status == 200)
                return response.data;
            return {};
        }).then(res => {
            let roomItemCategegory:any[] = [];
            res.map((projectItem: any, index: number) => {
                if(projectItem.details && projectItem.details.length > 0 ) {
                    roomItemCategegory.push(projectItem.itemCategoryId);
                }
            });

            setRoomItemCategegories(roomItemCategegory);
        }).catch(ex => {
            console.log(ex);
        });
    }

    useEffect(() => {
        getProjectRoomData();
    }, []);


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Box>
            <HeadCountRoomData project={project} 
                            revision={props?.revision} 
                            roomItemCategegories={roomItemCategegories} proceedTabChange={props.proceedTabChange} 
                            handleDisableNextTabs={props.handleDisableNextTabs}/>
        </Box>
    )
}

const officeDetailsFormFields = [
    {
        element: 'input',
        label: 'Carpet Area(sq ft)',
        placeholder: 'Carpet Area(sq ft)',
        name: 'carpetArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Built Up Area (sq ft)',
        placeholder: 'Built Up Area (sq ft)',
        name: 'builtUpArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Floor Plan Length (Mtr.)',
        placeholder: 'Floor Plan Length (Mtr.)',
        name: 'floorPlanLength',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Floor Plan Width (Mtr.)',
        placeholder: 'Floor Plan Width (Mtr.)',
        name: 'floorPlanWidth',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Open Ceiling Office Area (Sqm)',
        placeholder: 'Open Ceiling Office Area (Sqm)',
        name: 'openCeilingOfficeArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Closed Ceiling Office Area (Sqm)',
        placeholder: 'Closed Ceiling Office Area (Sqm)',
        name: 'closedCeilingOfficeArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'False Floor Office Area (Sqm)',
        placeholder: 'False Floor Office Area (Sqm)',
        name: 'falseFloorOfficeArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
   /* {
        element: 'input',
        label: 'Down Lights Proposed Area (Sqm)',
        placeholder: 'Down Lights Proposed Area (Sqm)',
        name: 'downLightsProposedArea',
        type: 'number',
        width: 2.4,
        required: true,
    },
    {
        element: 'input',
        label: 'Corridor Length (Mtr.)',
        placeholder: 'Corridor Length (Mtr.)',
        name: 'corridorLength',
        type: 'number',
        width: 2.4,
        required: true,
    },*/
    {
        element: 'input',
        label: 'Greenwall (Live) (No.s)',
        placeholder: 'Greenwall (Live) (No.s)',
        name: 'greenwall',
        type: 'number',
        width: 2.4,
        required: true,
    }
];

export default HeadCountTab;