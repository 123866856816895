import {
    Box,
    Button,
    Grid,
    Modal,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import Loader from "../../Loader";
import {getFormattedDate} from "../../../utils/Common";
import {Link} from "react-router-dom";
import {loadEventDetails} from "../../../modules/project/event/features/actions";
import {api} from "../../../utils/Axios";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import ListPage from "../../list-page/ListPage";
import {pageConfig} from "../../../modules/project/event/config/eventDetails";
import BreakBidsContent from "../../../modules/project/event/BreakBidsContent";
import EventBreakBidsPopup from "../../../modules/project/event/EventBreakBidsPopup";
import SelectEventRcVendor from "../../event/SelectEventRcVendor";
import ConfirmPopupAction from "../../common/ConfirmPopupAction";

const EventDetailsPopup = (props: any) => {
    const style = getStyle(100, 85);
    const [openPopup, setOpenPopup] = useState(false);
    const [eventDetails, setEventDetails] = useState<any>({});
    const [isLoading, setIsLoading] = useState(false);
    const [color, setColor] = useState("");
    const [openRevokeConfirm, setOpenRevokeConfirm] = useState(false);

    const rowsData: any = useAppSelector((state) => state.events.eventDetails) || {};

    const [selectedItem, setSelectedItem] = useState({});

    const [showRCVendorPopup, setShowRCVendorPopup] = useState(false);
    const [showBidsPopup, setShowBidsPopup] = useState(false);

    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState("id");
    const [sortDirection, setSortDirection] = useState<any>("desc");

    const dispatch = useAppDispatch();

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };
    const handlePopupCallBack = (data: any) => {
        setShowBidsPopup(false);
        if (data.event == 'save') {
            acceptBid();
        }
    };

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };

    const handleRCVendorPopupCallBack = (eventData: any) => {
        if (eventData.event == "save") {
            refreshPage();
        }
        setShowRCVendorPopup(false);
    }

    const handleCallback = (selectedRow: any, buttonData: any) => {
        setSelectedItem(selectedRow);
        if (buttonData.type == 'select-rc') {
            setShowRCVendorPopup(true);
        }
        if (buttonData.type == 'revoke') {
            setOpenRevokeConfirm(true);
        }
    }

    const handleConfirmModal = (flag: any) => {
        if (flag == 1) {
            revokePackageItem(selectedItem);
        }
        setOpenRevokeConfirm(false);
    }

    const revokePackageItem = (item: any) => {
        setIsLoading(true);
        api.get(
            `/procurement/package/revoke-item/${item.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                refreshPage();
                props.refreshPage();
            })
            .catch((ex) => {
                console.log(ex);
            }).finally(() => {
            setIsLoading(false);
        });
    }

    const acceptBid = () => {
        refreshPage();
        props.acceptBid();
    }

    const refreshPage = () => {
        dispatch(loadEventDetails(props.event.id)).then((_response: any) => {
            setTotalElements(10);
        });
    }

    const eventPackageDetails =
        rowsData?.eventPackages?.map((item: any, index: number) => ({
            ...item,
            id: item.id, packageStatus: item?.status || index,
        })) || [];

    const eventVendorsList =
        rowsData?.eventVendors?.map((item: any, index: number) => ({
            ...item,
            id: item.id || index,
        })) || [];

    useEffect(() => {
        if (props.openPopup) {
            refreshPage();
            let colorCode = '253, 182, 122';
            let name = props.event.status;
            if (name == 'CREATED') {
                colorCode = '223, 149, 113';
            }
            if (name == 'PR') {
                colorCode = '223, 112, 113';
            }
            if (name == 'EVENT') {
                colorCode = '223, 149, 149';
            }
            if (name == 'CLOSED') {
                colorCode = '55, 214, 112';
            }
            setColor(colorCode);
            setEventDetails(props.event);
            setOpenPopup(true);
        }
    }, [props.openPopup]);

    return (
        <>
            <Modal
                disableScrollLock={false}
                open={openPopup}
                onClose={handleClose}
                aria-labelledby="parent-modal-title"
                aria-describedby="parent-modal-description"
                sx={{
                    "& > .MuiBackdrop-root": {
                        backdropFilter: "blur(0px)",
                        backgroundColor: "transparent",
                    },
                }}
            >
                <Box sx={{
                    position: "absolute" as "absolute",
                    bottom: 0,
                    width: "100%",
                    height: '90%',
                    border: 0,
                    backgroundColor: "background.paper",
                    boxShadow: 1,
                    '&:focus-visible': {
                        outline: 'none'
                    }
                }}>
                    <Box sx={{position: "relative", height: "100%"}}>
                        <Box sx={style.header}>
                            <Box sx={style.headerContainer}>
                                <Box sx={{width: '95%'}}>
                                    <PageHeading
                                        title={"Event Details"}
                                        showButtons={false}
                                    />
                                </Box>
                                <Box onClick={handleClose}
                                     sx={{display: 'flex', justifyContent: 'right', width: '5%', cursor: 'pointer'}}>
                                    <CloseIcon/>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={"modal-body"} sx={style.modalFullBody}>
                            <Box sx={style.bodyContainer}>
                                {isLoading &&
                                    <Loader/>
                                }
                                <Box mb={4}>
                                    <Typography variant={"h4"} sx={{pb: 1}}>Project Details</Typography>
                                    <TableContainer
                                        component={"div"}
                                        sx={{
                                            border: "1px solid",
                                            borderColor: "divider",
                                            borderBottom: 0,
                                        }}
                                    >
                                        <Table
                                            sx={{minWidth: 650}}
                                            aria-label="project details table"
                                        >
                                            <TableHead sx={{backgroundColor: "info.contrastText"}}>
                                                <TableRow>
                                                    <TableCell align={"left"}>Project Name</TableCell>
                                                    <TableCell align={"left"}>Project Studio</TableCell>
                                                    <TableCell align={"left"}>Location</TableCell>
                                                    <TableCell align={"left"}>Carpet Area</TableCell>
                                                    <TableCell align={"left"}>Estimation Type</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell
                                                        align={"left"}
                                                        sx={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {props?.project?.name || "NA"}
                                                    </TableCell>
                                                    <TableCell
                                                        align={"left"}
                                                        sx={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {props?.project?.projectStudio || "NA"}
                                                    </TableCell>
                                                    <TableCell
                                                        align={"left"}
                                                        sx={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {props?.project?.location || "NA"}
                                                    </TableCell>
                                                    <TableCell
                                                        align={"left"}
                                                        sx={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        {props?.project?.carpetArea + " Sqft" || "NA"}
                                                    </TableCell>
                                                    <TableCell
                                                        align={"left"}
                                                        sx={{
                                                            textTransform: "capitalize",
                                                        }}
                                                    >
                                                        <Typography
                                                            sx={{
                                                                textTransform: "capitalize",
                                                                border: 1,
                                                                borderColor: `rgba(${color}, 1)`,
                                                                borderRadius: 1,
                                                                p: 0.6,
                                                                backgroundColor: `rgba(${color}, 0.5)`,
                                                                minWidth: "100px",
                                                                textAlign: "left",
                                                                width: "45%",
                                                            }}
                                                        >
                                                            {props?.project?.estimationType || "NA"}
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                <Box>
                                    <Typography variant={"h4"} sx={{pb: 1}}>Event Details</Typography>
                                    <Grid container
                                          sx={{
                                              p: 2,
                                              border: 1,
                                              borderColor: "divider",
                                              backgroundColor: "info.contrastText",
                                              fontWeight: '700'
                                          }}>
                                        <Grid item xs>Created On</Grid>
                                        <Grid item xs>Start Date</Grid>
                                        <Grid item xs>End Date</Grid>
                                        <Grid item xs>Total Vendors</Grid>
                                        <Grid item xs>Response Received</Grid>
                                        <Grid item xs>Event Status</Grid>
                                    </Grid>
                                    <Grid sx={{p: 2, border: 1, borderTop: 0, borderColor: "divider"}} container
                                          alignItems={"stretch"}>
                                        <Grid item xs>{getFormattedDate(eventDetails?.createdAt)}</Grid>
                                        <Grid item xs>{getFormattedDate(eventDetails?.startDate)}</Grid>
                                        <Grid item xs>{getFormattedDate(eventDetails?.endDate)}</Grid>
                                        <Grid item xs>{eventDetails?.totalVendors}</Grid>
                                        <Grid item xs>{eventDetails?.totalResponse}</Grid>
                                        <Grid item xs>
                                            <Typography sx={{
                                                textTransform: 'capitalize',
                                                border: 1,
                                                borderColor: `rgba(${color}, 1)`,
                                                borderRadius: 1,
                                                p: 0.6,
                                                backgroundColor: `rgba(${color}, 0.5)`,
                                                minWidth: '100px',
                                                textAlign: 'left',
                                                width: '50%'
                                            }}>
                                                {eventDetails?.status?.toLowerCase()}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Box>

                                {eventPackageDetails.map(
                                    (eventPackage: any, eventPackageIndex: number) => {
                                        const items = eventPackage.items.map((item: any) => {
                                            let cogsPrice = item.cogsPrice + item?.domainCogsCharges?.total;
                                            if (props.showUpdatedCharges) {
                                                let categoryCode = "";
                                                props.categories.map((category: any) => {
                                                    category.subCategories.map((subCategory: any) => {
                                                        if (subCategory.id == item?.itemDetail?.itemCategory.materialSubCategoryId) {
                                                            categoryCode = category.code;
                                                        }
                                                    });
                                                });
                                                if (categoryCode.toLowerCase() == "ff") {
                                                    cogsPrice = cogsPrice + ((cogsPrice * 2) / 100);
                                                } else {
                                                    cogsPrice = cogsPrice + ((cogsPrice * 6) / 100);
                                                }
                                            }
                                            let gsv = item.gsv + item?.domainGsvCharges?.total;

                                            const currentItem = {
                                                ...item,
                                                country: rowsData.project.country,
                                                gsv: gsv,
                                                cogsPrice: cogsPrice
                                            };
                                            currentItem.packageStatus = eventPackage?.packageStatus;
                                            return currentItem;
                                        });

                                        return (
                                            <Box key={"event-package-" + eventPackageIndex}>
                                                <Box sx={{mt: 4}}>
                                                    <Typography variant={"h4"}
                                                                sx={{pb: 1}}>{"Package: " + eventPackage?.name}</Typography>
                                                    <ListPage
                                                        rows={items}
                                                        columns={rowsData.status != "CLOSED" ? pageConfig.packageDetails : pageConfig.closedEventPackageDetails}
                                                        page={page}
                                                        size={size}
                                                        totalElements={totalElements}
                                                        sortColumn={sortColumn}
                                                        sortDirection={sortDirection}
                                                        handlePagination={handlePagination}
                                                        handleSort={handleSort}
                                                        handleCallBack={handleCallback}
                                                        hideFooter
                                                    />
                                                </Box>
                                            </Box>
                                        );
                                    }
                                )}

                                <Box mt={4}>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                    >
                                        <Typography variant={"h4"} sx={{pb: 1}}>Vendor Details</Typography>
                                    </Box>
                                    <TableContainer
                                        component={"div"}
                                        sx={{border: "1px solid", borderColor: "divider", borderBottom: 0}}
                                    >
                                        <Table sx={{minWidth: 650}} aria-label="simple table">
                                            <TableHead sx={{backgroundColor: "info.contrastText"}}>
                                                <TableRow>
                                                    <TableCell width={"6%"} align={"left"}>Sr. No.</TableCell>
                                                    <TableCell align={"left"}>Vendor Name</TableCell>
                                                    <TableCell align={"left"}>Vendor Comment (If Any)</TableCell>
                                                    <TableCell align={"left"}>Terms File</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {eventVendorsList.map((eventVendor: any, index: number) => {
                                                    return (
                                                        <TableRow key={eventVendor.vendorId}>
                                                            <TableCell align={"left"}>{index + 1}</TableCell>
                                                            <TableCell
                                                                align={"left"}>{eventVendor.vendor.name}</TableCell>
                                                            <TableCell align={"left"}>
                                                                {eventVendor?.comment != null ? eventVendor?.comment : "NA"}
                                                            </TableCell>
                                                            <TableCell align={"left"}>
                                                                {eventVendor?.termFile != null ? (
                                                                    <>
                                                                        <Link to={eventVendor.termFile}
                                                                              target={"_blank"}>View
                                                                            Terms</Link>
                                                                    </>
                                                                ) : ("NA")}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>

                                <Box my={4}>
                                    <Box
                                        display={"flex"}
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                        sx={{pb: 1}}
                                    >
                                        <Typography variant={"h4"} sx={{pb: 1}}>Break Bids</Typography>
                                        {rowsData &&
                                            rowsData.eventVendors.length > 1 &&
                                            rowsData.status != "CLOSED" &&
                                            Number(rowsData?.totalResponse) > 0 && (
                                                <Button
                                                    variant={"outlined"}
                                                    onClick={() => setShowBidsPopup(true)}
                                                >
                                                    Break Bids
                                                </Button>
                                            )}
                                    </Box>
                                    <BreakBidsContent
                                        showUpdatedCharges={props.showUpdatedCharges}
                                        eventStatus={rowsData.status}
                                        eventVendorsList={eventVendorsList}
                                        eventPackageDetails={eventPackageDetails}
                                        categories={props.categories}
                                        refreshPage={refreshPage}
                                        acceptBid={acceptBid}
                                    />
                                    {showBidsPopup && (
                                        <EventBreakBidsPopup
                                            categories={props.categories}
                                            showUpdatedCharges={props.showUpdatedCharges}
                                            eventId={eventDetails.id}
                                            showBidsPopup={showBidsPopup}
                                            handlePopupCallBack={handlePopupCallBack}
                                            eventVendorsList={eventVendorsList}
                                            eventPackageDetails={eventPackageDetails}
                                            rowsData={rowsData}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
            {showRCVendorPopup &&
                <SelectEventRcVendor
                    project={props.project}
                    selectedItem={selectedItem}
                    openPopup={showRCVendorPopup}
                    handleRCVendorPopupCallBack={handleRCVendorPopupCallBack}
                />
            }
            {openRevokeConfirm &&
                <ConfirmPopupAction openConfirmBox={openRevokeConfirm} handleButtonClick={handleConfirmModal}
                                    confirmText={"Do you want to revoke item from event?"}/>
            }
        </>
    )
}
export default EventDetailsPopup;