export function getParameterInputDetailsForComparison(tab: string) {
    if (tab) {
        return inputDetails[tab];
    }
    return null;
}


const inputDetails: any = {
    "c-and-i-common": [
        {
            groupName: 'DX Details',
            fields: [
                {
                    label: 'Type of DX details to be considered',
                    name: 'type-of-dx-details-to-be-considered',
                },
                {
                    label:
                        'Type of Base Flooring (Screeding / False Floor/ Screeding already done at site by Builder / client)',
                    name: 'type-of-base-flooring',
                },
                {
                    label: 'Cement Screed thickness in mm',
                    name: 'cement-screed-thickness',
                },
                {
                    label: 'Type of ceiling in workhall',
                    name: 'type-of-ceiling-in-workhall',
                },
                {
                    label: 'Type of Doors',
                    name: 'type-of-doors',
                }
            ]
        },
        {
            groupName: "Toilets Provided By Landlord",
            fields: [
                {
                    label: 'Toilets provided by the landlord',
                    name: 'toilets-provided-by-the-landlord',
                },
                {
                    label: 'Core cuts required for toilets and pantry',
                    name: 'core-cuts-required-for-toilets-and-pantry',
                },
                {
                    label: 'Total number of toilet blocks (male,female and other)',
                    name: 'total-number-of-toilet-blocks',
                },
                {
                    label: 'Total toilet area in Sqm',
                    name: 'total-toilet-area-in-sqm',
                },
                {
                    label: 'Total toilet periphery in Rmt',
                    name: 'total-toilet-periphery-in-rmt',
                },
            ]
        },
        {
            groupName: "Lift Count",
            fields: [
                {
                    label: 'Lift Lobby finishes provided by the landlord',
                    name: 'liftLobbyFinishesProvidedByTheLandlord',
                },
                {
                    label: 'Number of lifts (for calculating lobby finishes if lift lobby is not in Landlord scope)',
                    name: 'number-of-lifts',
                },
            ]
        },
        {
            groupName: "Ceiling & Glass Partition Height",
            fields: [
                {
                    label: 'Floor to Floor height (Mtr)',
                    name: 'floor-to-floor-height',
                },
                {
                    label: 'Glass partition Height (Mtr)',
                    name: 'glass-partition-height',
                },
            ]
        },
        {
            groupName: "Sill Height & Other Information",
            fields: [
                {
                    label: 'Sill height above floor level (mm)',
                    name: 'sill-height-above-floor-level',
                },
            ]
        },
        {
            groupName: "Fire exit doors",
            fields: [
                {
                    label: 'Fire exits door in SM scope',
                    name: 'fireExitsDoorInSMScope',
                },
                {
                    label: 'No of fire exit door/ Including main Entry',
                    name: 'fire_exit_door_plus_main_entry_count',
                },
            ]
        },
        {
            groupName: "SEZ Zone",
            fields: [
                {
                    label: 'SEZ Zone',
                    name: 'sez-zone',
                },
            ]
        },
        {
            groupName: "Chairs & Furnitures",
            fields: [
                {
                    label: 'Any Specific Make for Chairs',
                    name: 'any-specific-make-for-chairs',
                },
            ]
        },
        {
            groupName: "Measurable Arm",
            fields: [
                {
                    label: 'Quantity Buffer in measurable items (%)',
                    name: 'quantity-buffer-in-measurable-items',
                },
            ]
        },
        {
            groupName: "Chair Quantity Buffer",
            fields: [
                {
                    label: 'Chair Quantity Buffer (%)',
                    name: 'chairs',
                },
            ]
        }
    ],
    "ibms": [
        {
            groupName: 'Access Control System',
            fields: [
                {
                    label: 'Type of ACS', name: 'typeOfAcs',
                },
                {
                    label: 'Single door ACS', name: 'singleDoorAcs',
                },
                {
                    label: 'Double doors ACS', name: 'doubleDoorsAcs',
                },
                {
                    label: 'No. of ACS Doors', name: 'noOfAcsDoors',
                },
                {
                    label: 'No. of Fire Exit Doors', name: 'noOfFireExitDoors',
                },
                {
                    label: 'Electric Strick Lock requirement', name: 'electricStrickLockRequirement',
                },
                {
                    label: 'Scope of Access Card',
                    subLabel: 'If not specified select - No',
                    name: 'scopeOfAccessCard',
                },
                {
                    label: 'Scope of Access Main Server',
                    subLabel: 'If not specified select - No',
                    name: 'scopeOfAccessMainServer',
                },
                {
                    label: 'Scope of Access Recording PC',
                    subLabel: 'If not specified select - No',
                    name: 'scopeOfAccessRecordingPc'
                },
                {
                    label: 'Type of  Reader for Critical Room',
                    subLabel: 'If not specified select - Card',
                    name: 'typeOfReaderForCriticalRoom'
                },
            ],
        },
        {
            groupName: 'CCTV',
            fields: [
                {label: 'No. of CCTV', name: 'noOfCctv'},
                {label: 'Type of CCTV', name: 'typeOfCctv'},
                {label: 'CCTV Mega Pix', name: 'CCTVMegaPix'},
                {label: 'Open office area/Corridor - CCTV', name: 'openOfficeAreaCorridorCctv'},
                {label: 'Storage', name: 'storage',},
                {label: 'Recording', name: 'recording',},
                {label: 'Camera POE switch', name: 'CameraPOESwitch',},
                {label: 'Cloud license', name: 'CloudLicense',},
                {label: 'CCTV Monitor Scope', name: 'cctvMonitorScope',},
            ],
        },
        {
            groupName: 'BMS',
            fields: [
                {label: 'Scope: BMS', name: 'scopeBms'},
                {label: 'Scope: VMS', name: 'scopeVms'},
                {label: 'BMS Additional Info', name: 'bms_additoinal_info'},
            ],
        },
        {
            groupName: 'FA',
            fields: [
                {
                    label: 'FA Type', name: 'faType',
                },
                {
                    label: 'Repeater Panel', name: 'repeaterPanel',
                },
                {
                    label: 'Fire Graphics requirement', name: 'fireGraphicsRequirement',
                },
            ],
        },
        {
            groupName: 'PA System',
            fields: [
                {
                    label: 'PA System Dx Type', name: 'pa_system_dx_type',
                }
            ],
        },
        {
            groupName: 'WLD',
            fields: [
                {
                    label: 'Type of WLD system', name: 'typeOfWldSystem',
                },
            ],
        },
        {
            groupName: 'Rodent Control system',
            fields: [
                {label: 'Scope of Rodent', name: 'scopeOfRodent'},
            ],
        },
        {
            groupName: 'Vesda',
            fields: [
                {label: 'Scope of Vesda (aspiration system)', name: 'scopeOfVesda',},
                {label: 'Vesda Scope', name: 'vesdaScopeValue',},
            ],
        },
        {
            groupName: 'Flap Barrier /Turnstile',
            fields: [
                {label: 'Flap Barrier /Turnstile', name: 'flapBarrierTurnstileSelect',},
                {label: 'Type of Lane', name: 'normal_lane_dx_details',},
                {label: 'No of normal lane (500/550mm)', name: 'no_of_normal_lane',},
                {label: 'No of wider lane (900mm)', name: 'no_of_wider_lane'},
            ],
        },
    ],
    "phe-ff": [
        {
            groupName: 'Fire supression system',
            fields: [
                {label: "Scope of Sprinkler ", name: "scopeOfSprinkler"},
                {label: "Sprinkler (false ceiling void)", name: "sprinklerFalseCeilingVoidMore800"},
                {label: "Select Nos of tap off", name: "tap_off_count"},
                {label: "Scope of Gas supression system (Novec)", name: "scopeOfGasSupressionSystem"},
                {label: "Gas Supression System (Room Coverage)", name: "gss_room_coverage"},
                {label: "FE 36 Supression system", name: "fe36SupressionSystem"},
                {label: "FE 36 Supression system (Room Coverage)", name: "fe36_supression_system_room_coverage"},
                {label: "Fire extinguisher", name: "fireExtinguisher"},
                {label: "Fire extinguisher (Room Coverage)", name: "fire_extinguisher_room_coverage"},
                {label: "Type of Fire Curtain", name: "fire_curtain_type"},
                {label: "Fire Curtain requirement in sqm.", name: "fireCurtainRequirementInSqm"},
            ]
        },
        {
            groupName: 'PHE',
            fields: [
                {label: "EWC Count", name: "ewc"},
                {label: "Wash Basin Count", name: "wash-basin"},
                {label: "Urinal Count", name: "urinal"},
                {
                    label: "Lifting pump for wet pantry /mothers/wellness room for away from the plumbing shaft ",
                    name: "lifting-pump-for-wet-pantry"
                },
                {label: "Select Qty if Wet point from the PHE shaft?", name: "wet_point_qty"}
            ]
        }
    ],
    "hvac": [
        {
            groupName: 'HVAC',
            fields: [
                {
                    label: "Workspace", name: 'workspace'
                },
                {
                    label: "24/7 area", name: '24/7Area'
                },
                {
                    label: "Dedicated", name: 'dedicated',
                },
            ]
        },
        {
            groupName: 'Scope',
            fields: [
                {label: "VAV", name: "vav"},
                {label: "Under deck", name: "underDeck"},
            ]
        },
        {
            fields: [
                {label: "AHU QTY", name: "ahuQty"},
                {label: "Landlord AHU CFM", name: "landlordAhuCfm"},
                {label: "AHU Wall Acoustic in Mtr", name: "ahuWallAcousticInMtr"},
            ]
        }
    ],
    electrical: [
        {
            groupName: 'Electrical',
            fields: [
                {label: 'Workspace Operation', name: 'workspaceOperation'},
                {label: 'Workspace Operation ration (Laptop: 1Monitor: 2Monitor)', name: 'workspaceOperationRation'},
            ],
        },
        {
            groupName: 'Scope',
            fields: [
                {label: 'HAT', name: 'scope-hat'},
                {label: 'Add-on RP Soc', name: 'add-on-rp-soc'},
                {label: 'USB', name: 'scopeUsb'},
            ],
        },
        {
            fields: [
                {label: 'Floor Tap-off no.', name: 'floorTapOffNo'},
                {label: 'UPS Requirement', name: 'upsRequirement'},
                {label: 'Conduit Type', name: 'conduitType'},
                {label: 'Lighting Design', name: 'lightingDesign'},
                {label: 'Lighting Control System', name: 'lightingControlSystem'},
                {label: 'AH Netbox', name: 'ah_netbox_inScope'},
            ],
        },
    ],
    'info-tech': [
        {
            groupName: 'IT',
            fields: [
                {label: 'IT Node details', name: 'itNodeDetails'},
                {label: 'Node % requirement', name: 'nodeRequirement'},
                {label: 'Backbone cabling- Fiber', name: 'backboneCablingFiber'},
                {label: 'PDU', name: 'pdu'},
            ],
        },
    ],
    'mep': [
        {
            groupName: "MEP - IT Node Count",
            fields: [
                {label: 'Length in mtr. (Avg.)- Copper.', name: 'lengthInMtrCopper'},
                {label: 'Length in mtr. (Avg.) - Fiber', name: 'lengthInMtrFiber'},
            ]
        },
        {
            groupName: "MEP - FF Calculation",
            fields: [
                {label: 'Cylinder Weight for HUB / IDF Room', name: 'cylinder_weight_hub_idf_room'},
            ]
        },
    ],
    'ltCable': [
        {
            groupName: "LT Cable",
            fields: [
                {label: 'UPS Panel Each Cable Length in mtr', name: 'ups_cable_length_mtr'},
                {label: 'SPN DBs  Each Cable Length in mtr', name: 'spn_db_cable_length_mtr'},
                {label: 'SPN DBs  UPS Cable Size', name: 'spn_db_cable_size'},
                {label: 'MELDB Cable Length in mtr', name: 'meldb_cable_length_mtr'},
                {label: 'MELDB Cable Size', name: 'meldb_cable_size'},
                {label: 'HVAC Units Cable Length in mtr', name: 'hvac_cable_length_mtr'},
            ]
        },
    ]
}