import React from 'react';
import {Box} from '@mui/material'
import TextTransition, { presets } from 'react-text-transition';


const TEXTS = ['Loading Estimation', 'Preparing Model', 'Rendering Results'];

function EstimationTextSwiper(props:any) {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(
      () => setIndex((index) => index + 1),
      3000, // every 3 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  return (
    <Box className="estimationSliderTextOuter" sx={{textAlign: 'center', margin: '5px 0 20px'}}>
      <TextTransition className="estimationSliderText" springConfig={presets.gentle}>{TEXTS[index % TEXTS.length]}</TextTransition>
    </Box>
  );
};

export default EstimationTextSwiper;