export const config = [
    {
        id: 'individual_workpoints',
        name: 'Individual Workpoints',
        disabled: false,
        items: [
            {
                id: "linear_laminated_workstation", name: "Linear Laminated",
                skus: [
                    {
                        id: 'linear_laminated_workstation_1',
                        name: 'Linear Laminated workstation as per layout',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: 'innov8/head_count/linearLaminatedworkstationasperlayout.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'},
                    },
                    {
                        id: 'linear_laminated_workstation_2',
                        name: 'Linear Laminated HAT workstation as per layout',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        image: 'innov8/head_count/linearLaminatedHATworkstationasperlayout.png',
                        countHide: true,
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            },
            {
                id: "l_type_laminated_workstation", name: "L-Type Laminated",
                skus: [
                    {
                        id: 'l_type_laminated_workstation_1',
                        name: 'L type Laminated workstation as per layout',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/ltypeLaminatedworkstationasperlayout.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'l_type_laminated_workstation_2',
                        name: 'L type Laminated HAT workstation as per layout',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/ltypeLaminatedHATworkstationasperlayout.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            },
            {
                id: "120_degree_laminated_workstation", name: "120 Degree Laminated",
                skus: [
                    {
                        id: 'l_type_laminated_workstation_3',
                        name: '120 Degree laminated Workstations',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/120DegreelaminatedWorkstations.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'l_type_laminated_workstation_4',
                        name: '120 Degree laminated HAT Workstations',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/120DegreelaminatedHATWorkstations.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            },
            {
                id: "laminated_pedestal", name: "Laminated Pedestal",
                skus: [
                    {
                        id: 'laminated_pedestal_1',
                        name: 'Laminated Pedestal',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/laminatedPedestal.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            },
            {
                id: "shared_laminated_pedestal", name: "Shared Laminated",
                skus: [
                    {
                        id: 'shared_laminated_pedestal_1',
                        name: 'Shared Laminated Pedestal',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/sharedLaminatedPedestal.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_2',
                        name: 'Metal Pedestal',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/metalPedestal.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_3',
                        name: 'Shared Metal Pedestal',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/sharedMetalPedestal.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            },
            {
                id: "lockers", name: "Lockers",
                skus: [
                    {
                        id: 'shared_laminated_pedestal_4',
                        name: 'Laminated Lockers with Numeric lock and Key',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/laminatedLockerswithNumericlockandKey.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_5',
                        name: 'Metal Lockers with Numeric lock and Key',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/metalLockerswithNumericlockandKey.png",
                        specifications: {name: 'Room Type', value: 'Powder coated finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_6',
                        name: 'Laminated Lockers with RFID Lock',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/laminatedLockerswithRFIDLock.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_7',
                        name: 'Metal Lockers with RFID lock',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/metalLockerswithRFIDlock.png",
                        specifications: {name: 'Room Type', value: 'Powder coated finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_8',
                        name: 'Laminated Lockers with RFID and Bluetooth based lock',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/laminatedLockerswithRFIDandBluetoothbased lock.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_9',
                        name: 'Metal Lockers with RFID and Bluetooth based lock',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/metalLockerswithRFIDandBluetoothbasedlock.png",
                        specifications: {name: 'Room Type', value: 'Powder coated finish'}
                    }
               ]
            },
            {
                id: "individual_workpoints", name: "Individual Workpoints",
                skus: [
                    {
                        id: 'shared_laminated_pedestal_10',
                        name: 'Type A- Senior Management Open Cubicle',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/TypeA-SeniorManagementOpenCubicle.jpg",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'shared_laminated_pedestal_11',
                        name: 'Type B - Senior Management Open Cubicle with Partition',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        countHide: true,
                        image: "innov8/head_count/TypeB-SeniorManagementOpenCubicleWithPartition.jpg",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'cabins', name: 'Cabins',
        disabled: false,
        items: [
            {
                id: 'item-cabins', name: 'Cabins',
                skus: [
                    {
                        id: 'cabins_1',
                        name: 'MD Cabin/CEO table in',
                        roomCount: 3,
                        roomType: '',
                        qtyHide: true,
                        image: 'innov8/head_count/ceoTablein.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'cabins_2',
                        name: 'Director Cabin table in',
                        roomCount: 3,
                        roomType: '',
                        qtyHide: true,
                        image: "innov8/head_count/directorcabinfurniture500x500.webp",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'cabins_3',
                        name: 'HOD table in',
                        roomCount: 1,
                        roomType: '',
                        qtyHide: true,
                        image: "innov8/head_count/hodTable.jpg",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'cabins_4',
                        name: 'Manager Cabins table in',
                        roomCount: 6,
                        roomType: '',
                        qtyHide: true,
                        image: 'innov8/head_count/executive4.webp',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'cabins_5',
                        name: 'Executive Cabin',
                        roomCount: 6,
                        roomType: '',
                        qtyHide: true,
                        image: 'innov8/head_count/executive4.webp',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'cabins_6',
                        name: 'Huddles Room',
                        roomCount: 6,
                        roomType: '',
                        qtyHide: true,
                        image: 'innov8/head_count/executive4.webp',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    /*{
        id: 'workstations', name: 'Workstations',
        disabled: false,
        items: [
            {
                id: 'item_workstations', name: 'Workstations',
                skus: [
                    {
                        id: 'workstations_1',
                        name: 'Workstations + Hotdesk Seat',
                        quantity: 408,
                        roomCount: 6,
                        roomType: '',
                        image: "innov8/head_count/Workstation-348.png",
                        typeHide: true,
                        countHide: true,
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'workstations_2',
                        name: 'Workstation (Laptop)',
                        quantity: 0,
                        roomCount: 0,
                        roomType: '',
                        image: "innov8/head_count/workstationLaptop.png",
                        typeHide: true,
                        countHide: true,
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'workstation_laptop_single_monitor',
                        name: 'Workstation LWorkstation (Laptop + Single Monitor)',
                        quantity: 0,
                        roomCount: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/workstationLWorkstationLaptopSingleMonitor.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'workstations_4',
                        name: 'Workstation (Laptop + Dual Monitor)',
                        quantity: 0,
                        roomCount: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/workstationLaptoDualMonitor.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },*/
    {
        id: 'meeting_rooms_in_laminate_finish',
        name: 'Meeting Rooms',
        disabled: false,
        items: [
            {
                id: 'item_meeting_rooms_in_laminate_finish', name: 'Meeting Rooms in Laminate finish',
                skus: [
                    {
                        id: 'meeting_rooms_in_laminate_finish_1',
                        name: '2 pax- (Interview Room)',
                        roomCount: 6,
                        qtyHide: true,
                        image: "innov8/head_count/meetingRoomAVScreens.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_laminate_finish_2',
                        name: '4 pax Rm',
                        roomCount: 1,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/4paxRmMeeting.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_laminate_finish_3',
                        name: '6 pax Rm',
                        roomCount: 1,
                        qtyHide: true,
                        roomType: 'veneer-finish',
                        image: "innov8/head_count/6paxRmMeeting.png",
                        specifications: {name: 'Room Type', value: 'Veneer Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_laminate_finish_4',
                        name: '8 pax Rm',
                        roomCount: 3,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/8paxRmMeeting.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_laminate_finish_5',
                        name: '10 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/10paxRmMeeting.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'board_rooms',
        name: 'Board Rooms',
        disabled: false,
        items: [
            {
                id: 'item_board_rooms', name: 'Board Rooms',
                skus: [
                    {
                        id: 'board_rooms_1',
                        name: '12 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/12paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'board_rooms_2',
                        name: '14 pax Board room',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/14paxBoardroom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'board_rooms_3',
                        name: '16 pax Board room',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/16paxBoardroom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'board_rooms_4',
                        name: '20 pax Board room',
                        roomCount: 1,
                        qtyHide: true,
                        image: "innov8/head_count/20paxBoardroom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'training_room_20_pax',
        name: 'Training Room',
        disabled: false,
        items: [
            {
                id: 'item_training_room_20_pax', name: 'Training Room',
                skus: [
                    {
                        id: 'training_room_20_pax_0',
                        name: 'Training Room- 20 Pax',
                        roomCount: 6,
                        qtyHide: true,
                        image: "innov8/head_count/othersTrainingRoom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'training_room_others',
                        name: 'Training Room - Others',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/othersTrainingRoom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'training_room_20_pax_2',
                        name: 'Training Room- 40 Pax',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/training40pax.jpeg",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'meeting_rooms_in_formal',
        name: 'Meeting Rooms- In Formal',
        disabled: false,
        items: [
            {
                id: 'item_meeting_rooms_in_formal', name: "Meeting Rooms- In Formal",
                skus: [
                    {
                        id: 'meeting_rooms_in_formal_1',
                        name: '4 pax Rm',
                        roomCount: 4,
                        qtyHide: true,
                        image: "innov8/head_count/4paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_formal_2',
                        name: '6 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/6paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_rooms_in_formal_3',
                        name: '8 pax Rm',
                        roomCount: 3,
                        qtyHide: true,
                        image: "innov8/head_count/8paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'phone_booths_focus_room_built_in',
        name: 'Phone booths/ Focus room (Built In)',
        disabled: false,
        items: [
            {
                id: 'item_phone_booths_focus_room_built_in',
                name: 'Phone booths/ Focus room (Built In)',
                skus: [
                    {
                        id: 'phone_booths_focus_room_built_in_1',
                        name: '1 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/1paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booths_focus_room_built_in_2',
                        name: '2 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/2paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booths_focus_room_built_in_3',
                        name: '3 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/3paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booths_focus_room_built_in_4',
                        name: '4 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/4paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'phone_booths_focus_room_modular',
        name: 'Phone booths/ Focus room (MODULAR)',
        disabled: false,
        items: [
            {
                id: 'item_phone_booths_focus_room_modular',
                name: 'Phone booths/ Focus room (MODULAR)',
                skus: [
                    {
                        id: 'phone_booth_modular_1_pax',
                        name: '1 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/1paxRm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booth_modular_2_pax',
                        name: '2 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/2paxRm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booth_modular_3_pax',
                        name: '3 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/3paxRm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'phone_booth_modular_4_pax',
                        name: '4 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/4paxRm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'open_collaboration_space',
        name: 'Open Collaboration Space',
        disabled: false,
        items: [
            {
                id: 'item_open_collaboration_space',
                name: 'Open Collaboration Space',
                skus: [
                    {
                        id: 'open_collaboration_space_1',
                        name: '4 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/4paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'open_collaboration_space_2',
                        name: '6 pax Rm',
                        roomCount: 2,
                        qtyHide: true,
                        image: "innov8/head_count/6paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'open_collaboration_space_4',
                        name: '8 pax Rm',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/8paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'open_collaboration_space_5',
                        name: '10 pax Rm',
                        roomCount: 2,
                        qtyHide: true,
                        image: "innov8/head_count/10paxRm.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'work_cafe_break_area',
        name: 'Work Cafe/ Break Area',
        disabled: false,
        items: [
            {
                id: 'item_work_cafe_break_area',
                name: 'Work Cafe/ Break Area',
                skus: [
                    {
                        id: 'work_cafe_break_area',
                        name: 'Work Cafe/ Break Area',
                        quantity: 0,
                        roomCount: 0,
                        typeHide: true,
                        showSpace: true,
                        image: "innov8/head_count/breakArea.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'pantry_points',
        name: 'Pantry Points',
        disabled: false,
        items: [
            {
                id: 'item_pantry_points',
                name: 'Pantry Points',
                skus: [
                    /*{
                        id: 'pantry_points',
                        name: 'Pantry Points',
                        roomCount: 0,
                        image: "innov8/head_count/pantryPoints.png",
                        typeHide: true,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },*/
                    {
                        id: 'main_rooms_28',
                        name: 'Close Pantry (Wet Pantry)',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/closedPantry(wet pantry).png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_29',
                        name: 'Open Pantry',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/openPantry.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    },
    {
        id: 'booths',
        name: 'Booths',
        disabled: false,
        items: [
            {
                id: 'item_booths',
                name: 'Medium height storage with Planter',
                skus: [
                    {
                        id: 'booth_seating_2px',
                        name: 'Booth seating 2Pax',
                        roomCount: 0,
                        qtyHide: true,
                        image: "innov8/head_count/boothseating2Pax.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'booth_seating_4px',
                        name: 'Booth seating 4Pax',
                        roomCount: 0,
                        qtyHide: true,
                        roomType: 'laminate-finish',
                        image: "innov8/head_count/boothseating4Pax.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'booth_seating_6px',
                        name: 'Booth seating 6Pax',
                        roomCount: 2,
                        qtyHide: true,
                        image: "innov8/head_count/boothseating6Pax.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'general_room_details',
        name: 'General Room Details',
        disabled: false,
        items: [
            {
                id: 'item_general_room_details',
                name: 'Medium height storage with Planter',
                skus: [
                    {
                        id: 'step_seating_count',
                        name: 'Step seating no. of Seating',
                        quantity: 18,
                        countHide: true,
                        typeHide: true,
                        image: "innov8/head_count/stepseatingnoofSeating.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'medium_height_storage_with_planter',
                        name: 'Medium Height Storage with Planter',
                        quantity: 0,
                        countHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/reproArea.png',
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'workhall_column_count',
                        name: 'No. of Columns in open workhall area',
                        quantity: 0,
                        countHide: true,
                        typeHide: true,
                        image: "innov8/head_count/noofColumnsinopenworkhallarea.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'training_rooms_mpr_no_of_seats_1',
                        name: 'Training Rooms/ MPR (No of seats)',
                        quantity: 0,
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/trainingRoom.png",
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'training_room_seats_others',
                        name: 'Training Rooms Seats in Others',
                        quantity: 0,
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/trainingRoom.png",
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'vav_room_1',
                        name: 'VAV',
                        quantity: 0,
                        typeHide: true,
                        countHide: true,
                        roomType: '',
                        image: "innov8/head_count/vav.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'elv_and_ibms_1',
                        name: 'ELV & IBMS',
                        quantity: 0,
                        typeHide: true,
                        countHide: true,
                        roomType: '',
                        image: 'innov8/head_count/elvSystemPro.jpg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'meeting_room_av_screens_1',
                        name: 'Meeting Room AV Screens',
                        quantity: 0,
                        typeHide: true,
                        countHide: true,
                        roomType: '',
                        image: 'innov8/head_count/meetingRoomAVScreens.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'medical_room',
        name: 'Medical Room',
        disabled: false,
        items: [
            {
                id: 'item_medical_room',
                name: 'Medical Room',
                skus: [
                    {
                        id: 'medical_room_q',
                        name: 'Medical Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: "innov8/head_count/medicalRoom.png",
                        roomType: 'laminate-finish',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'monitor_arms',
        name: 'Monitor Arms',
        disabled: false,
        items: [
            {
                id: 'item_monitor_arms',
                name: 'Monitor Arms',
                skus: [
                    {
                        id: 'monitor_arms_1',
                        name: 'Single Monitor Arm',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/singleMonitorArm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'monitor_arms_2',
                        name: 'Dual Monitor Arm',
                        quantity: 0,
                        roomType: 'laminate-finish',
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/dualMonitorarm.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'main_rooms',
        name: 'Main Rooms',
        disabled: false,
        items: [
            {
                id: 'item_main_rooms',
                name: 'Main Rooms',
                skus: [
                    {
                        id: 'main_rooms_1',
                        name: 'Reception',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        showSpace: true,
                        roomType: '',
                        image: 'innov8/head_count/reception.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_2',
                        name: 'Arrival Area',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image:'innov8/head_count/arrivalArea.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_3',
                        name: 'Mother Room',
                        roomCount: 2,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/motherRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_4',
                        name: 'NAP Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/napRoom.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_5',
                        name: 'Game / Recreation Area',
                        roomCount: 1,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/game.jpeg',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_6',
                        name: 'Gym',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/gym.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_7',
                        name: 'BMS Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/bmsRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_8',
                        name: 'Security Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/small-office-security-social.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_9',
                        name: 'Mail Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/mail.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_10',
                        name: 'NOC Room',
                        roomCount: 0,
                        image: 'innov8/head_count/nocRoom.png',
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_11',
                        name: 'SOC Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/socRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_12',
                        name: 'GSOC Room',
                        roomCount: 0,
                        image: 'innov8/head_count/gsocRoom.png',
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_13',
                        name: 'Transport Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/transportRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_14',
                        name: 'Wellness Room',
                        roomCount: 0,
                        image: 'innov8/head_count/wellnessRoom.png',
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_15',
                        name: 'Collabs',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/collabs.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_16',
                        name: 'HUB/ IDF Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/idfRoom.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_17',
                        name: 'Server/ MDF Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/mdfRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_18',
                        name: 'Floor Electrical Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/floorElectricalRoom.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_19',
                        name: 'UPS Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/upsRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_21',
                        name: 'Battery Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/batteryRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_22',
                        name: 'Repro',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/reproArea.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_23',
                        name: 'IT Store Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/itStoreRoom.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_24',
                        name: 'AHU Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/ahuRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_25',
                        name: 'Locker Room',
                        roomCount: 0,
                        image: 'innov8/head_count/lockerRoom.png',
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_26',
                        name: 'Builder Electrical Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/builderElectricalRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_27',
                        name: 'Store Room',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: 'innov8/head_count/storeRoom.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_30',
                        name: 'Toilets',
                        roomCount: 0,
                        image: 'innov8/head_count/toilets.png',
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_31',
                        name: 'Live Kitchen',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: 'innov8/head_count/liveKitchen.png',
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'main_rooms_32',
                        name: 'Cafeteria',
                        quantity: 0,
                        roomCount: 0,
                        typeHide: true,
                        showSpace: true,
                        roomType: '',
                        image: 'innov8/head_count/cafeteria.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'kitchen_bain_marie_1',
                        name: 'Kitchen / Baine Marie',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        roomType: '',
                        image: "innov8/head_count/baineMarie.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'handwash_1',
                        name: 'Handwash',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: "innov8/head_count/handwash.png",
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'dishwasher_1',
                        name: 'Dishwasher',
                        roomCount: 0,
                        qtyHide: true,
                        typeHide: true,
                        image: "innov8/head_count/dishwasher.png",
                        roomType: '',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'critical_equipment_details',
        name: 'Critical Equipment Details',
        disabled: false,
        items: [
            {
                id: 'item_critical_equipment_details_mdf',
                name: 'Server / MDF Room',
                skus: [
                    {
                        id: 'critical_equipment_details_2',
                        name: 'Server rack',
                        quantity: 10,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/serverRack2.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }, {
                        id: 'critical_equipment_details_3',
                        name: 'Network rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/networkRack500x500.webp',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'critical_equipment_details_4',
                        name: 'Mux rack',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/muxRack.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }, {
                        id: 'critical_equipment_details_5',
                        name: 'Voice rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/voiceRack.jpeg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            },
            {
                id: 'item_critical_equipment_details_hub',
                name: 'HUB / IDF Room (1)',
                skus: [
                    {
                        id: 'critical_equipment_details_7',
                        name: 'Server rack',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/serverRack.jpeg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }, {
                        id: 'critical_equipment_details_8',
                        name: 'Network rack',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/network rack2.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'critical_equipment_details_9',
                        name: 'Mux rack',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/muxRack.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'critical_equipment_details_10',
                        name: 'Voice rack',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/voiceRack.jpeg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            },
            {
                id: 'item_critical_equipment_details_hub',
                name: 'HUB / IDF Room (2)',
                skus: [
                    {
                        id: 'hub_idf_room_2_server_rack',
                        name: 'Server rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/serverRack.jpeg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }, {
                        id: 'hub_idf_room_2_network_rack',
                        name: 'Network rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/network rack2.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'hub_idf_room_2_mux_rack',
                        name: 'Mux rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/muxRack.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'hub_idf_room_2_voice_rack',
                        name: 'Voice rack',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/voiceRack.jpeg',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    }
                ]
            }
        ]
    },
    {
        id: 'lab_equipment_details',
        name: 'LAB Equipment Details',
        disabled: false,
        items: [
            {
                id: 'item_lab_equipment_details',
                name: 'LAB Equipment Details',
                skus: [
                    {
                        id: 'lab_equipment_details_1',
                        name: 'Lab Racks',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/labRacks.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'lab_equipment_details_2',
                        name: 'Lab Desk',
                        quantity: 10,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: "innov8/head_count/labDesk.png",
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'lab_equipment_details_3',
                        name: 'Lab Equipments- 3Phase',
                        quantity: 0,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/labEquipments3Phase.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                    {
                        id: 'lab_equipment_details_4',
                        name: 'Lab Equipments- 1Phase',
                        quantity: 18,
                        roomType: '',
                        typeHide: true,
                        countHide: true,
                        image: 'innov8/head_count/labEquipments1Phase.png',
                        specifications: {name: 'Room Type', value: 'Laminate Finish'}
                    },
                ]
            }
        ]
    }
];

//export const spaceDetailsConfig = ["Closed Area (Sqm.)", "Open Area (Sqm.)", "Watts:", "Socket Set:", "Dec. Set", "Sprinkler", "Length", "Breadth", "Height"]
export const spaceDetailsConfig = ["Closed Area (Sqm.)"]