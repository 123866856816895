import {Box, Checkbox, FormControlLabel, Tab, Tabs, Typography} from "@mui/material";
import Loader from "../../Loader";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import ParameterCategoryComparison from "./parameters-comparison/ParameterCategoryComparison";

const ParametersComparison = (props: any) => {
    const [tabValue, setTabValue] = useState("");
    const [categoryTabs, setCategoryTabs] = useState<any>([]);
    const [selectedParameter, setSelectedParameter] = useState<any>({});
    const [hideCommon, setHideCommon] = useState(true);

    const [tabs, setTabs] = useState<any>({
        'c-and-i-common': {
            name: 'C&I + Common',
            disabled: false,
        },
        'ibms': {
            name: 'IBMS',
            disabled: true,
        },
        'phe-ff': {
            name: 'PHE & FF',
            disabled: true,
        },
        'hvac': {
            name: 'HVAC',
            disabled: true,
        },
        'electrical': {
            name: 'Electrical',
            disabled: true,
        },
        'info-tech': {
            name: 'IT',
            disabled: true,
        },
        'mep': {
            name: 'MEP',
            disabled: true,
        },
        'ltCable': {
            name: 'LT Cable',
            disabled: true,
        }
    });

    const handleHideCommon = () => {
        setHideCommon(!hideCommon);
    }

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    useEffect(() => {
        if (tabValue) {
            let selectedRevisionParameter: any = {};
            props.revisionParameters.map((revisionParameter: any) => {
                revisionParameter.parameters.map((parameter: any) => {
                    if (parameter.categoryName == tabValue) {
                        selectedRevisionParameter[revisionParameter.id] = parameter.data;
                    }
                });
            });
            setSelectedParameter(selectedRevisionParameter);
        }
    }, [tabValue]);

    useEffect(() => {
        if (props.revisionParameters.length > 0) {
            let categories: any[] = [];
            props.revisionParameters.map((revisionParameter: any) => {
                const parameterCategories = revisionParameter.parameters.map((parameter: any) => parameter.categoryName);
                if (parameterCategories.length > categories) {
                    categories = parameterCategories;
                }
            });
            setCategoryTabs(categories);
            setTabValue(categories[0]);
        }
    }, [props.revisionParameters]);

    if (props.isParametersLoading) {
        return <Loader/>
    }

    return (
        <Box sx={{pb: 8}}>
            <Box className={"space-between vertically-center-aligned"} sx={{border: 1, borderColor: '#333333', borderRadius: '4px', px:2}}>
                <Typography variant={"h3"} sx={{fontSize: '18px', fontWeight: 600}}>Parameter Comparison</Typography>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"info"}
                                checked={hideCommon}
                                onChange={handleHideCommon}
                                sx={{
                                    color: '#0486FF',
                                    '&.Mui-checked': {
                                        color: "#0486FF",
                                    },
                                }}
                            />
                        }
                        label="Hide Common Details"
                    />
                </Box>
            </Box>
            <Box sx={{px: 4}}>
                <Tabs
                    value={tabValue}
                    onChange={handleTabChange}
                    aria-label="Site Conditions & Assumptions Tabs"
                    textColor="primary"
                    indicatorColor="primary"
                    sx={{
                        display: "flex",
                        my: 4,
                        "& .MuiTabs-indicator": {
                            display: 'none',
                        },
                        "& .MuiTabs-scroller": {
                            display: "flex !important",
                        },
                        borderColor: 'divider'
                    }}
                    TabIndicatorProps={{
                        sx: {
                            backgroundColor: 'info.main',
                        },
                    }}

                >
                    {categoryTabs.map((categoryTabValue: string, index: number) => {
                        const tab = tabs[categoryTabValue];
                        return (
                            <Tab
                                key={categoryTabValue + '_' + index}
                                {...a11yProps(categoryTabValue, true)}
                                value={categoryTabValue}
                                label={tab.name}
                                wrapped
                                sx={{
                                    fontWeight: tabValue === categoryTabValue ? 'bold' : 500,
                                    borderRadius: 5,
                                    px: 4,
                                    mr: 2,
                                    border: 1,
                                    borderColor: '#D7D7D7',
                                    color: tabValue === categoryTabValue ? '#1E2B51' : '#1E2B51',
                                    '&.Mui-selected': {
                                        color: '#fff !important',
                                        backgroundColor: "#3854E4",
                                        borderColor: '#3854E4',
                                    },
                                }}
                            />
                        )
                    })}
                </Tabs>
                {tabValue != "" &&
                    <CustomTabPanel value={tabValue} index={tabValue}>
                        <ParameterCategoryComparison selectedParameter={selectedParameter} category={tabValue} hideCommon={hideCommon}/>
                    </CustomTabPanel>
                }
            </Box>
        </Box>
    )
}

export default ParametersComparison;