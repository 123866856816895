import {useEffect, useState} from "react";
import {api} from "../../utils/Axios";
import Loader from "../Loader";
import ListPage from "../list-page/ListPage";
import {packageListColumns, packageListReadOnlyColumns} from "../../modules/project/plan/config/PackageListColumns";
import CreateEventPopup from "./CreateEventPopup";
import PackageDetails from "./popup/PackageDetails";
import {useNavigate} from "react-router-dom";

const PackageList = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [openPackageDetailPopup, setOpenPackageDetailPopup] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState<any>({});
    const [packageTotal, setPackageTotal] = useState(0);
    const [rows, rowChange] = useState([]);
    const [page, pageChange] = useState(0);
    const [sortColumn, setSortColumn] = useState('id');
    const [sortDirection, setSortDirection] = useState<any>('desc');
    const [size, setSize] = useState(10);
    const [totalElements, setTotalElements] = useState(1);

    const handlePackageDetailCallBack = (data: any) => {
        if(data.event == 'revoke') {
            loadPackages();
        } else {
            setOpenPackageDetailPopup(false);
        }
    }

    const handleSort = (sortModel: any) => {
        if (sortModel && sortModel.field) {
            setSortColumn(sortModel.field);
            setSortDirection(sortModel.sort);
            pageChange(0);
        }
    };
    const handlePagination = (newPagination: any) => {
        setSize(newPagination.pageSize);
        pageChange(newPagination.page);
    };

    const handleCallBack = (rowData: any, buttonData: any) => {
        if (buttonData.type === 'anchor') {
            setSelectedPackage(rowData);
            setOpenPackageDetailPopup(true);
            // navigate(`/projects/package/${rowData.id}`);
        }
        if (buttonData.type === 'revoke') {
            if (rowData.status === 'CREATED')
                revokePackage(rowData.id);
            else {
                console.log("Not available");
            }
        }

        if (buttonData.type == 'edit') {
            props.handleCallback({event: 'edit', selectedRow: rowData});
        }

        if (buttonData.type === 'publish') {
            let total = parseFloat(rowData.total) + parseFloat(rowData?.domainGsvCharges?.total);
            setPackageTotal(total);
            setSelectedPackage(rowData);
            setOpenPopup(true);
        }
    };

    const revokePackage = (id: string) => {
        setIsLoading(true);
        api.get(`/procurement/package/revoke/${id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            loadPackages();
            props.handleCallback({event: "reload"});
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const loadPackages = () => {
        setIsLoading(true);
        api.get(`/procurement/package/list/${props.project.id}`, {
            params: {
                pageNo: page,
                pageSize: size,
                sort: sortDirection?.toUpperCase(),
                sortByColumn: sortColumn
            }
        }).then((response) => {
            return response.data;
        }).then((response: any) => {
            rowChange(response.content);
            setTotalElements(response.totalElements);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const handlePopupCallBack = (data: any) => {
        setOpenPopup(false);
        setSelectedPackage({});
        if (data.hasOwnProperty("prPackage"))
            props.handleCallback({event: data.prPackage});
    }

    const callBackReload = (data: any) => {
        props.handlePendingCallback();
    }

    const handleApprovePr = () => {
        props.handleCallback({event: "created"});
    }

    useEffect(() => {
        loadPackages();
    }, [props.project]);

    if (isLoading) {
        return <Loader/>;
    }

    return (
        <>
            <ListPage
                rows={rows}
                columns={props.project.status == 'COMPLETED' ? packageListReadOnlyColumns : packageListColumns}
                page={page}
                size={size}
                totalElements={totalElements}
                sortColumn={sortColumn}
                sortDirection={sortDirection}
                handlePagination={handlePagination}
                handleSort={handleSort}
                handleCallBack={handleCallBack}
            />
            {openPopup &&
                <CreateEventPopup
                    handlePopupCallBack={handlePopupCallBack}
                    openPopup={openPopup}
                    packageTotal={packageTotal}
                    package={selectedPackage}
                    projectId={props.project.id}
                />
            }
            {openPackageDetailPopup &&
                <PackageDetails
                    aPackage={selectedPackage}
                    project={props.project}
                    categories={props.categories}
                    openPopup={openPackageDetailPopup}
                    handleCallBack={handlePackageDetailCallBack}
                    handlePublishCallback={loadPackages}
                    handleApprovePr={handleApprovePr}
                    callBackReload={callBackReload}
                />
            }
        </>
    )
}

export default PackageList;