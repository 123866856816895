import Loader from "../../../Loader";
import React, {useEffect, useState} from "react";
import {Box, Button, Checkbox, FormControlLabel, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CatalogItemComparison from "./CatalogItemComparison";

const CatalogComparison = (props: any) => {
    const [hideCommon, setHideCommon] = useState(true);
    const [expandSubCategory, setExpandSubCategory] = useState<any>({});

    const handleExpand = (subCategoryId: any) => {
        const newExpandSubCategory = {...expandSubCategory, [subCategoryId]: !expandSubCategory[subCategoryId]};
        setExpandSubCategory(newExpandSubCategory);
    }

    const handleHideCommon = () => {
        setHideCommon(!hideCommon);
    }

    const checkSimilarSkuAvailable = (currentRevisionId: string, currentSku: any) => {
        let flag = false;
        let mismatchFound = false;
        props.revisionCatalog.map((catalogDetails: any) => {
            if (catalogDetails.id !== currentRevisionId) {
                let skuFoundInThisRevision = false;
                catalogDetails.estimationItems.map((estimationItem: any) => {
                    estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                        if (estimationItemSku.sku.id == currentSku.sku.id) {
                            skuFoundInThisRevision = true;
                            if (!mismatchFound) {
                                if (estimationItemSku.hasOwnProperty("commonValues")) {
                                    flag = estimationItemSku.commonValues;
                                    mismatchFound = true;
                                } else if (estimationItemSku.quantity == currentSku.quantity && estimationItemSku.sellingPrice == currentSku.sellingPrice) {
                                    flag = true;
                                } else {
                                    flag = false;
                                    mismatchFound = true;
                                }
                            }
                        }
                    });
                });
                if(!skuFoundInThisRevision) {
                    flag = false;
                    mismatchFound = true;
                }
            }
        });
        return flag;
    }

    useEffect(() => {
        props.revisionCatalog.map((catalogDetails: any) => {
            catalogDetails.estimationItems.map((estimationItem: any) => {
                estimationItem.commonValues = true;
                estimationItem.estimationItemSkus.map((estimationItemSku: any) => {
                    const commonValues = checkSimilarSkuAvailable(catalogDetails.id, estimationItemSku);
                    estimationItemSku.commonValues = commonValues;
                    if (!commonValues) {
                        estimationItem.commonValues = false;
                    }
                });
            });
        });

        props.catalogSubCategories.map((subCategory: any) => {
            subCategory.commonValues = true;
            let fieldDetails: any = {};
            props.revisionCatalog.map((catalogDetails: any) => {
                let allEstimationItems: any[] = [];
                catalogDetails.estimationItems.map((estimationItem: any) => {
                    if (estimationItem.itemCategory.materialSubCategoryId == subCategory.id) {
                        if (!estimationItem.commonValues) {
                            subCategory.commonValues = false;
                        }
                        if (estimationItem.estimationItemSkus.length > 0) {
                            allEstimationItems = [...allEstimationItems, ...estimationItem.estimationItemSkus];
                        }
                    }
                });
                if (allEstimationItems.length > 0)
                    fieldDetails[catalogDetails.id] = allEstimationItems;
            });

            subCategory.revisions = fieldDetails;
        });

        handleExpand(props.catalogSubCategories[0].id);
    }, [props.revisionCatalog]);

    if (props.isCatalogueLoading) {
        return <Loader/>
    }
    return (
        <Box sx={{pb: 8}}>
            <Box className={"space-between vertically-center-aligned"}
                 sx={{border: 1, borderColor: '#333333', borderRadius: '4px', px: 2}}>
                <Typography variant={"h3"} sx={{fontSize: '18px', fontWeight: 600}}>E Catalog</Typography>
                <Box>
                    <FormControlLabel
                        control={
                            <Checkbox
                                color={"info"}
                                checked={hideCommon}
                                onChange={handleHideCommon}
                                sx={{
                                    color: '#0486FF',
                                    '&.Mui-checked': {
                                        color: "#0486FF",
                                    },
                                }}
                            />
                        }
                        label="Hide Common Details"
                    />

                </Box>
            </Box>
            <Box sx={{py: 2, px: 4}}>
                {props.catalogSubCategories.map((subCategory: any, subCategoryIndex: number) => {
                    if ((!hideCommon || (hideCommon && !subCategory.commonValues)) && subCategory.hasOwnProperty('revisions') && Object.keys(subCategory.revisions).length > 0) {
                        return (
                            <Box key={subCategory.id + "-e-catalog-subcategory-comparison-" + subCategoryIndex}>
                                <Box className={"vertically-center-aligned space-between"} onClick={() => handleExpand(subCategory.id)} sx={{
                                    p: 2,
                                    backgroundColor: "#E9EBF9",
                                    cursor: 'pointer'
                                }}>
                                    <Typography sx={{fontWeight: 700}}>{subCategory.name}</Typography>
                                    <Button sx={{py: 0}}>
                                        {expandSubCategory[subCategory.id] ? (
                                            <KeyboardArrowUpIcon/>
                                        ) : (
                                            <ExpandMoreIcon/>
                                        )}
                                    </Button>
                                </Box>
                                <Box sx={{py: 2}}>
                                    {expandSubCategory[subCategory.id] &&
                                        <CatalogItemComparison subCategory={subCategory} hideCommon={hideCommon}/>
                                    }
                                </Box>
                            </Box>
                        )
                    }
                })}
            </Box>
        </Box>
    )
}

export default CatalogComparison;