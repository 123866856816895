import React, { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { Box} from "@mui/material";
import SearchBarComponent from "./SearchBarComponent";

const SearchBar = (props: any) => {
  const [searchQuery, setSearchQuery] = useState<string>(props.query || "");

  useEffect(() => {
    setSearchQuery(props.query || "");
  }, [props.query]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
  };

  const handleSearch = () => {
    props.handleSearchFilter(searchQuery);

    const filtersWithSearch = { ...props.appliedFilters, searchQuery};
    props.handleFilterChange(filtersWithSearch);
  };

  const handleFilterApply = (data: any) => {
    if (data.event === "submit") {
      const newFilters = Object.fromEntries(
        Object.entries(data.form).filter(
          ([, value]: any) =>
            (typeof value === "string" && value.trim() !== "") ||
            (typeof value === "number" && value !== null) ||
            (Array.isArray(value) && value.length > 0)
        )
      );

      props.handleFilterChange(newFilters);
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
        {props.showFilters && (
            <>
                <SearchBarComponent
                    filters={props.filters}
                    handleFilterApply={handleFilterApply}
                    appliedFilters={props.appliedFilters}
                    range={props.range ? props.range : [0, 100]}
                    minDistance={props.minDistance ? props.minDistance : 10}
                    selectOptions={props.selectOptions}
                    showItemCategoryFilter={props.showItemCategoryFilter}
                    subCategory={props.subCategory}
                    updatePriceFilterType={props.updatePriceFilterType}
                />
            </>
        )}
        {props.showSearch && (
            <Box sx={{ width: "100%",maxWidth: "300px", paddingTop: "54px", marginRight: "18px" }}>
                <TextField
                    size="small"
                    variant="outlined"
                    fullWidth
                    value={searchQuery}
                    onChange={handleOnChange}
                    onKeyDown={handleKeyDown}
                    placeholder={"Search..."}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end" onClick={handleSearch} sx={{ cursor: "pointer" }}>
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                />
            </Box>
        )}
    </Box>
);
};

export default SearchBar;
