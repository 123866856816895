import React, {useCallback, useEffect, useState} from "react";
import Loader from "../Loader";
import {api} from "../../utils/Axios";
import {Box, Button, Typography} from "@mui/material";
import ListEstimationSkusPopup from "./sku-popup/ListEstimationSkusPopup";
import {handleRemoveItem} from "../../modules/project/plan/tabs/estimation-tabs/features/actions";
import {openSnackbar} from "../common/features/snackbarSlice";
import {
    ERROR_COLOR,
    REMOVED_ITEM_CATEGORY_FAILED,
    REMOVED_ITEM_CATEGORY_SUCCESSFULLY,
    SUCCESS_COLOR
} from "../../constants";
import {useAppDispatch} from "../../app/hooks";
import ListItemCategoryAndItems from "./ListItemCategoryAndItems";
import SearchFilter from "../filter/SearchFilter";
import RemoveFilters from "../filter/RemoveFilters";
import useDebounce from "./hooks/useDebounce";
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const EstimationItemListing = (props: any) => {
    const dispatch = useAppDispatch();
    const [isItemCategoriesLoading, setIsItemCategoriesLoading] = useState(false);
    const [isEstimationItemsLoading, setIsEstimationItemsLoading] = useState(false);
    const [openPopup, setOpenPopup] = useState(false);
    const [showBoqItems, setShowBoqItems] = useState(false);
    const [skuSelectionItem, setSkuSelectionItem] = useState({});
    const [itemCategories, setItemCategories] = useState<any>([]);
    const [itemDetails, setItemDetails] = useState<any>([]);
    const [selectedItems, setSelectedItems] = useState<any>([]);
    const [searchQuery, setSearchQuery] = useState("");
    const [appliedFilters, setAppliedFilters] = useState({});

    const handleShowBoqItems = () => {
        setShowBoqItems(!showBoqItems);
    }

    const handleSearchFilter = (query: any) => {
        setSearchQuery(query);
    };

    const handleFilterChange = (appliedParams: any) => {
        setAppliedFilters(appliedParams);
    };

    const handleSkuPopupCallBack = (eventData: any) => {
        setOpenPopup(false);
    };

    const loadItemCategories = useCallback(() => {
        const controller = new AbortController();
        const signal = controller.signal;
        setIsItemCategoriesLoading(true);
        setItemCategories([]);
        const url = `/catalogue/item-category/get-for-all/${props.project.country}/${props.project.location}/${props.subCategoryId}`;
        const params = new URLSearchParams({searchQuery});
        Object.entries(appliedFilters).forEach(([name, value]: any) => {
            params.append(name, value);
        });

        api.get(url, {params, signal})
            .then(response => response.data)
            .then(response => {
                if (!signal.aborted) {
                    setItemDetails(response);
                }
            })
            .catch(ex => {
                if (!signal.aborted) {
                    console.log(ex);
                }
            })
            .finally(() => {
                setIsItemCategoriesLoading(false);
            });

        return () => controller.abort();
    }, [searchQuery, appliedFilters, props.subCategoryId]);

    const loadEstimationItems = useCallback(() => {
        const controller = new AbortController();
        const signal = controller.signal;

        setSelectedItems([]);
        setIsEstimationItemsLoading(true);
        const url = `/procurement/estimation/get-selected-items/${props.revision.id}/${props.subCategoryId}`;

        api.get(url, {signal})
            .then(response => response.data)
            .then(response => {
                if (!signal.aborted) {
                    setSelectedItems(response);
                }
            })
            .catch(ex => {
                if (!signal.aborted) {
                    console.log(ex);
                }
            })
            .finally(() => {
                setIsEstimationItemsLoading(false);
            });

        return () => controller.abort();
    }, [props.project.id, props.subCategoryId]);

    const deleteItem = async (toDeleteItem: any) => {
        try {
            const response = await dispatch(
                handleRemoveItem({
                    revisionId: props?.revision.id,
                    materialSubCategoryId: props?.subCategoryId,
                    itemId: toDeleteItem.id,
                })
            );
            if (response.payload) {
                itemCategories.forEach((itemCategory: any) => {
                    itemCategory.items.forEach((item: any) => {
                        if (item.id === toDeleteItem.id) {
                            delete item.total;
                            item.totalQuantity = "";
                            delete item.totalSelectedSkus;
                        }
                    });
                });
                setItemCategories([...itemCategories]);

                dispatch(
                    openSnackbar({
                        message: `${toDeleteItem.name}: ${REMOVED_ITEM_CATEGORY_SUCCESSFULLY}`,
                        backgroundColor: SUCCESS_COLOR,
                    })
                );
            }
        } catch (err: any) {
            dispatch(
                openSnackbar({
                    message: REMOVED_ITEM_CATEGORY_FAILED,
                    backgroundColor: ERROR_COLOR,
                })
            );
        }
    };

    const handleSkuSelection = (item: any, itemCategoryId: any) => {
        item.itemCategoryId = itemCategoryId;
        setSkuSelectionItem(item);
        setOpenPopup(true);
    };

    const debouncedLoadItemCategories = useDebounce(loadItemCategories, 300);
    useEffect(() => {
        debouncedLoadItemCategories();
    }, [debouncedLoadItemCategories]);

    useEffect(() => {
        let onlyBoqItems = props.project.estimationType == 'scratch' ? false : true;
        setShowBoqItems(onlyBoqItems);
        setSearchQuery("");
        setAppliedFilters({});
        loadEstimationItems();
    }, [props.subCategoryId, loadEstimationItems]);

    useEffect(() => {
        if (Array.isArray(selectedItems) && selectedItems.length > 0) {
            const updatedItemCategories = itemDetails.map((itemCategory: any) => {
                let isItemSelected = false;
                const items = itemCategory.items.map((item: any) => {
                    const selectedItem = selectedItems.find((si: any) => si.itemId === item.id);
                    if (selectedItem && selectedItem.totalQuantity > 0) {
                        isItemSelected = true;
                        return {
                            ...item,
                            totalSelectedSkus: selectedItem.totalSkus,
                            totalQuantity: selectedItem.totalQuantity,
                            total: selectedItem.total,
                        };
                    }
                    return item;
                });
                return {
                    ...itemCategory,
                    isItemSelected,
                    items
                }
            });
            setItemCategories(updatedItemCategories);
        } else {
            setItemCategories(itemDetails);
        }
    }, [selectedItems, itemDetails]);

    if (isItemCategoriesLoading || isEstimationItemsLoading) {
        return <Loader/>;
    }

    return (
        <>
            <Box className={"estimation-block"} sx={{overflow: 'auto', maxHeight: 'calc(100vh - 135px)'}}>
                <Box sx={{pb: 2, alignItems: 'center'}} className={'right-align-content'}>
                    <Box sx={{px: 2}} className={"center-aligned"}>
                        <Box component={"span"} sx={{pr: 1, pt: 0.5}}>
                            {showBoqItems ? (
                                <ToggleOnIcon fontSize={"large"} color={"primary"} onClick={handleShowBoqItems}/>
                            ) : (
                                <ToggleOffIcon fontSize={"large"} color={"primary"} onClick={handleShowBoqItems}/>
                            )}
                        </Box>
                        <Typography sx={{width: '120px', display: 'inline-block'}}
                                    component={"span"}>{showBoqItems ? "Show All" : "Show BoQ Items"}</Typography>
                    </Box>
                    <SearchFilter
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        appliedFilters={appliedFilters}
                        showFilters={true}
                        showSearch={true}
                        query={searchQuery}
                        handleSearchFilter={handleSearchFilter}
                    />
                </Box>
                <Box>
                    <RemoveFilters
                        handleFilterChange={handleFilterChange}
                        filters={filters}
                        appliedFilters={appliedFilters}
                    />
                </Box>

                <ListItemCategoryAndItems
                    showBoqItems={showBoqItems}
                    itemCategories={itemCategories}
                    showNoItemsSelected={!(Array.isArray(selectedItems) && selectedItems.length > 0 && showBoqItems)}
                    project={props.project}
                    handleSkuSelection={handleSkuSelection}
                    handleShowBoqItems={handleShowBoqItems}
                    deleteItem={deleteItem}
                />
            </Box>
            {openPopup && (
                <ListEstimationSkusPopup
                    revision={props.revision}
                    subCategoryId={props.subCategoryId}
                    project={props.project}
                    openPopup={openPopup}
                    item={skuSelectionItem}
                    showBoqItems={showBoqItems}
                    handleSkuPopupCallBack={handleSkuPopupCallBack}
                />
            )}
        </>
    );
};


const filters = {
    'name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Item Category Name',
        label: 'Item Category Name',
        name: 'name'
    },
    'code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Item Category Code',
        label: 'Item Category Code',
        name: 'code'
    },
    'item_name': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Item Name',
        label: 'Item Name',
        name: 'item_name'
    },
    'item_code': {
        element: 'input',
        type: 'text',
        placeholder: 'Search By Item Code',
        label: 'Item Code',
        name: 'item_code'
    },
    'RC_price': {
        element: 'radio',
        label: 'RC Price',
        name: 'RC_price',
        options: [
            {id: 'yes', label: 'Yes'},
            {id: 'no', label: 'No'},
        ],
    },
    'price': {
        element: 'slider',
        placeholder: 'Search Here',
        label: 'Price Range',
        name: 'price',
        info: '1 = 1000',
        range: [0, 1000000]
    }
}

export default EstimationItemListing;
