import {Box, Button, Grid, Tab, Tabs, Typography} from "@mui/material";
import React, {SyntheticEvent, useEffect, useState} from "react";
import {a11yProps, CustomTabPanel} from "../../../utils/TabsUtils";
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import EqualizerOutlinedIcon from '@mui/icons-material/EqualizerOutlined';
import {hasAccess} from "../../../utils/Access";
import CompareRevisionTabular from "./CompareRevisionTabular";
import Loader from "../../Loader";
import {api} from "../../../utils/Axios";
import {formatEstimationWithRevisionData, getCSVFormatRevisionEstimations} from "../../../utils/downloadHelper";
import SelectCompareRevisions from "./SelectCompareRevisions";

const CompareRevisionMain = (props: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isRevisionLoading, setIsRevisionLoading] = useState(false);

    const [revisionEstimations, setRevisionEstimations] = useState([]);
    const [tabValue, setTabValue] = useState("table");
    const [selectedRevisions, setSelectedRevisions] = useState<any>([]);
    const [tabs, setTabs] = useState([
        {id: "table", name: <TableChartOutlinedIcon fontSize={"small"}/>},
        {id: "graph", name: <EqualizerOutlinedIcon fontSize={"small"}/>}
    ]);

    const handleTabChange = (event: SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleSave = (data: any) => {
        setSelectedRevisions(data);
    }

    const loadProjectDetails = () => {
        setIsLoading(true);
        api
            .get(`/procurement/project/project-details/${props.project.id}`)
            .then((response) => {
                return response.data;
            })
            .then((res) => {
                props.project.projectDetails = res;
                setIsLoading(false);
            })
            .catch((ex) => {
                console.log(ex);
                setIsLoading(false);
            });
    };

    const downloadComparison = () => {
        if (revisionEstimations.length > 0) {
            setIsRevisionLoading(true);
            let form = {'revisionIds': selectedRevisions};
            api.post(`/procurement/estimation/revision/estimation-details/${props.project.id}`, form)
                .then(response => response.data)
                .then(res => {
                    const formattedData = formatEstimationWithRevisionData(res, props.showUpdatedCharges);
                    if (formattedData.length > 0) {
                        const csvString = getCSVFormatRevisionEstimations(formattedData, props.showUpdatedCharges);
                        const blob = new Blob([csvString], {type: "text/csv"});
                        setIsRevisionLoading(false);
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.download = "revision-estimations" + new Date() + ".csv";
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                        markDownloaded();
                    }
                })
                .catch(ex => {
                    console.log(ex);
                })
                .finally(() => {
                    setIsRevisionLoading(false);
                });
        }
    }

    const getEstimationRevisionsForComparison = () => {
        setIsRevisionLoading(true);
        let form = {'revisionIds': selectedRevisions};
        api.post(`/procurement/estimation/revision/estimation-comparison/${props.project.id}`, form)
            .then(response => response.data)
            .then(res => {
                setRevisionEstimations(res);
            })
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsRevisionLoading(false);
            });
    }

    const markDownloaded = () => {
        let form = {'revisionIds': selectedRevisions};
        api.post(`/procurement/estimation/revision/mark-downloaded/${props.project.id}`, form)
            .then(response => response.data)
            .catch(ex => {
                console.log(ex);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    useEffect(() => {
        if (selectedRevisions.length > 0) {
            getEstimationRevisionsForComparison();
        }
    }, [selectedRevisions]);

    useEffect(() => {
        if (props.project.projectDetails == null) {
            loadProjectDetails();
        }
    }, []);

    if (isLoading || isRevisionLoading) {
        return <Loader/>
    }
    return (
        <Box>
            <Box>
                <Box
                    sx={{
                        position: "absolute",
                        width: '81%',
                        top: 0,
                        left: '20%',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}>
                    <Tabs
                        value={tabValue}
                        onChange={handleTabChange}
                        indicatorColor="secondary"
                        variant={"fullWidth"}
                        sx={{
                            width: "18%",
                            fontFamily: 'Lato',
                            "& .Mui-selected": {
                                backgroundColor: "primary.main",
                                color: "#fff!important",
                                fontWeight: 700,
                                p: 0,
                                minWidth: '30px',
                                maxWidth: '60px',
                            },
                            "& .MuiTab-wrapped": {
                                p: 0,
                                minWidth: '30px',
                                maxWidth: '60px',
                            }
                        }}
                        aria-label="wrapped label tabs example"
                        TabIndicatorProps={{
                            sx: {
                                backgroundColor: "primary.main",
                            },
                        }}
                    >
                        {tabs.map((tab: any, index: number) => (
                            <Tab
                                key={tab.id + "_" + index}
                                {...a11yProps(tab.id, true)}
                                value={tab.id}
                                label={tab.name}
                                wrapped
                                sx={{border: 1, borderColor: "divider"}}
                            />
                        ))}
                    </Tabs>

                    <Box>
                        {(hasAccess(['super-admin']) && selectedRevisions.length > 0) &&
                            <Button variant="contained" color="primary" onClick={downloadComparison}>
                                Download Comparison
                            </Button>
                        }
                    </Box>
                </Box>
            </Box>
            <Box sx={{py: 2}}>
                <CustomTabPanel value={tabValue} index={tabValue}>
                    <CompareRevisionTabular
                        showUpdatedCharges={props.showUpdatedCharges}
                        tab={tabValue}
                        revisionEstimations={revisionEstimations}
                        revisions={props.revisions}
                        project={props.project}
                        selectedRevisions={selectedRevisions}
                        categories={props.categories}
                        handleSave={handleSave}
                    />
                </CustomTabPanel>
            </Box>
        </Box>
    )
}
export default CompareRevisionMain;