import React, {SyntheticEvent, useEffect, useState} from "react";
import {api} from "../../../utils/Axios";
import {hasAccess} from "../../../utils/Access";
import Loader from "../../../components/Loader";
import {Box, Modal} from "@mui/material";
import {formatData, getCSVFormatEstimation} from "../../../utils/downloadHelper";
import SubCategoryTab from "../../../components/tabs/SubCategoryTab";
import CategoryTab from "../../../components/tabs/CategoryTab";
import {CustomTabPanel} from "../../../utils/TabsUtils";
import EstimationItemListing from "../../../components/estimation/EstimationItemListing";
import {getStyle} from "../../../utils/ModalUtils";
import PageHeading from "../../../components/page-heading/PageHeading";
import CloseIcon from "@mui/icons-material/Close";
import EstimationSummary from "../../../components/estimation/summary/EstimationSummary";

import SiteConditionTab from "../plan/tabs/SiteConditionTab";
import HeadCountTab from "../../../components/head-count/HeadCountTab";
import ECatalogTab from "../plan/tabs/ECatalogTab";

const EstimationDetailsPopupNew = (props: any) => {
    const {revision, project, categories} = props;
    const style = getStyle(100, 85);
    const [openPopup, setOpenPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [headerButtons, setHeaderButtons] = useState<any>([]);

    const [currentRevision, setCurrentRevision] = useState<any>({});
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [categoryTabValue, setCategoryTabValue] = useState("summary");
    const [subCategoryTabValue, setSubCategoryTabValue] = useState("");
    //const [showUpdatedCharges, setShowUpdatedCharges] = useState(true);

    const [showProjectParameters, setShowProjectParameters] = useState(true);
    const [projectWithRevision, setProjectWithRevision] = useState(props.project);
    const [hideDownload, setHideDownload] = useState(false);

    const [tabs, setTabs] = useState(
        [
            {id: "site-condition", name: "Parameters", disabled: false},
            {id: "head-count", name: "HC", disabled: true},
            {id: "e-catalog", name: "E-Catalog", disabled: true},
            {id: "summary", name: "Summary", disabled: project.estimationType === 'scratch' ? false  : true}
        ]
    );

    const handleCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setCategoryTabValue(newValue);
    };

    const handleSubCategoryTabChange = (_event: SyntheticEvent, newValue: string) => {
        setSubCategoryTabValue(newValue);
    };

    const refreshRevision = () => {
        setOpenPopup(false);
        props.proceedTabChange("boq");
    }

    const loadEstimationDetails = async () => {
        try {
            setIsLoading(true);
            const response = await api.get(`/procurement/estimation/details/${currentRevision.id}`);
            const results = await response.data;
            if (!currentRevision?.downloaded) {
                markDownloaded();
            }
            downloadCSV(results);
            setIsLoading(false);
        } catch (err) {
            console.log(err);
            setIsLoading(false);
        }
    };

    const markDownloaded = () => {
        api.get(`/procurement/estimation/revision/mark-downloaded/${currentRevision.id}`).then((response) => {
            return response.data;
        }).then((response: any) => {
            setCurrentRevision(response);
            project.revision = response;
            setProjectWithRevision(project);
            setIsLoading(false);
        }).catch(ex => {
            console.log(ex);
            setIsLoading(false);
        });
    }

    const downloadCSV = (estimations: any) => {
        const formattedData = formatData(estimations, categories, props.showUpdatedCharges);
        const csvString = getCSVFormatEstimation(formattedData, props.showUpdatedCharges);
        const blob = new Blob([csvString], {type: "text/csv"});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "report.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const handleClose = () => {
        setOpenPopup(false);
        props.handleCallBack({event: 'close'});
    }

    const handleHeaderButtonClick = async (data: any) => {
        if (data === "download-estimation") {
            loadEstimationDetails();
        }
    }

    const proceedTabChange = (id: string, data: any) => {
        let hideEstimationTab = false;
        let hideEngineTabs = false;
        if (id == 'site-condition' && (data?.type == 'scratch' || data?.type == 'clone')) {
            if (project?.stage == 'DETAILS') {
                hideEngineTabs = true;
            }
            id = 'estimation';
            if(project?.sourceEstimationType == "engine") {
                id = 'site-condition';
            }
        } else {
            if (project?.stage == 'DETAILS') {
                hideEstimationTab = true;
            }
        }

        tabs.map((tab: any) => {
            if (hideEstimationTab && tab.id == 'estimation') {
                tab.disabled = true;
            }
            if (hideEngineTabs && (["site-condition", "head-count", "e-catalog"].includes(tab.id))) {
                tab.disabled = true;
            }

            if (tab.id == id) {
                tab.disabled = false;
            }
        });

        switch (id) {
            case "head-count":
                project.stage = "HEADCOUNT";
                break;
            case "e-catalog":
                project.stage = "CATALOG";
                break;
            case "summary":
                project.stage = "ESTIMATION";
                break;
        }

        setCategoryTabValue(id);
    }

    const handleDisableNextTabs = (id: string, changeTab: any) => {
        console.log("handleDisableNextTabs called");
    }

    const updateShowProjectParameters = () => {
        let showParameters = false;
        if(project.estimationType ===  "engine" || (project.estimationType ===  "clone"  &&  project.sourceEstimationType  === "engine")) {
            showParameters = true;
        }

        setShowProjectParameters(showParameters);
    }

    useEffect(() => {
        if (categoryTabValue !== "summary" && categoryTabValue !== "" && categories.length > 0) {
            categories.forEach((category: any) => {
                if (category.id === categoryTabValue) {
                    setSelectedCategory(category);
                    if(category.code == "FF") {
                        if(category.subCategories[0].code == "CA") {
                            setSubCategoryTabValue(category.subCategories[1]?.id || "");
                        } else {
                            setSubCategoryTabValue(category.subCategories[0]?.id || "");
                        }
                    } else {
                        setSubCategoryTabValue(category.subCategories[0]?.id || "");
                    }
                }
            });
        } else {
            setSubCategoryTabValue("");
        }
    }, [categoryTabValue, categories]);

    useEffect(() => {
        setHeaderButtons([{
            id: "download-estimation",
            label: "Download Estimation",
            variant: "contained",
            color: "primary",
            permissions: ["super-admin", "edit-projects"],
        }]);
        
        //project.stage = "PARAMETER";
        switch (project.stage) {
            case "DETAILS":
            case "PARAMETER":
                tabs.map((tab: any) => {
                    if (["site-condition"].includes(tab.id)) {
                        tab.disabled = false;
                    }
                });
                break;
            case "HEADCOUNT":
                tabs.map((tab: any) => {
                    if (["site-condition", "head-count"].includes(tab.id)) {
                        tab.disabled = false;
                    }
                });
                break;
            case "CATALOG":
                tabs.map((tab: any) => {
                    if (project.estimationType === "scratch") {
                        if (tab.id == "estimation") {
                            tab.disabled = false;
                        }
                    }
                    if (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine')) {
                        if (["site-condition", "head-count", "e-catalog"].includes(tab.id)) {
                            tab.disabled = false;
                        }
                    }
                });
                break;
            case "ESTIMATION":
                tabs.map((tab: any) => {
                    if (project.estimationType === "scratch") {
                        if (tab.id == "estimation") {
                            tab.disabled = false;
                        }
                    }
                    if (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine')) {
                        if (["site-condition", "head-count", "e-catalog", "summary"].includes(tab.id)) {
                            tab.disabled = false;
                        }
                    }
                });
                setHideDownload(false);
                break;
            default:
                tabs.map((tab: any) => {
                    tab.disabled = false;
                });
                break;
        }
        if(["DETAILS","PARAMETER", "HEADCOUNT", "CATALOG"].includes(project.stage) && (project.estimationType === 'engine' || (project.estimationType === 'clone' && project.sourceEstimationType === 'engine'))) {
            setCategoryTabValue("site-condition");
            setHideDownload(true);   
        } else {
            setCategoryTabValue("summary");
        }
        setCurrentRevision(revision);
        project.revision = revision;
        setProjectWithRevision(project);
    }, [revision]);

    useEffect(() => {
        updateShowProjectParameters();
        if (props.openPopup) {
            setOpenPopup(true);
            /*let updatedCharges = true;
            if (hasAccess(["procurement-head", "business-head", "super-admin"])) {
                updatedCharges = false;
            }
            setShowUpdatedCharges(updatedCharges);
            */
        }
    }, [props.openPopup]);


    if (isLoading) {
        return <Loader/>;
    }

    return (
        <Modal
            disableScrollLock={false}
            open={openPopup}
            onClose={handleClose}
            aria-labelledby="parent-modal-title"
            aria-describedby="parent-modal-description"
            sx={{
                "& > .MuiBackdrop-root": {
                    backdropFilter: "blur(0px)",
                    backgroundColor: "transparent",
                },
            }}
        >
            <Box sx={{
                position: "absolute" as "absolute",
                bottom: 0,
                width: "100%",
                height: '90%',
                border: 0,
                backgroundColor: "background.paper",
                boxShadow: 1,
                '&:focus-visible': {
                    outline: 'none'
                }
            }}>
                <Box sx={{position: "relative", height: "100%"}}>
                    <Box sx={style.header}>
                        <Box sx={style.headerContainer}>
                            <Box sx={{width: '95%'}}>
                                <PageHeading
                                    title={"Project: " + project.name +" (Revision: " + currentRevision?.name+")"}
                                    showButtons={true}
                                    buttons={headerButtons}
                                    handleHeaderButtonClick={handleHeaderButtonClick}
                                />
                            </Box>
                            <Box onClick={handleClose}
                                 sx={{display: 'flex', justifyContent: 'center', width: '5%', cursor: 'pointer'}}>
                                <CloseIcon/>
                            </Box>
                        </Box>
                    </Box>
                    <Box className={"modal-body"} sx={style.modalFullBody}>
                        <Box sx={style.bodyContainer}>
                            <Box className={"common-space-container"}>
                                <Box display={"flex"} alignItems={"flex-start"} sx={{pb: 1}}>
                                    <Box sx={{width:'100%'}}>
                                        <Box>
                                            {categoryTabValue !== "" && (
                                                <CategoryTab
                                                    hideZeroSkus={false}
                                                    includeSummaryTab={true}
                                                    categories={categories}
                                                    disableCategories={project.status == 'COMPLETED' || (["DETAILS", "PARAMETER", "HEADCOUNT", "ECATALOG"].includes(project.stage) && project.estimationType === 'engine') ? true : false}
                                                    categoryTabValue={categoryTabValue}
                                                    handleCategoryTabChange={handleCategoryTabChange}
                                                    showProjectParameters={showProjectParameters}
                                                    tabs={tabs}
                                                />
                                            )}
                                            {selectedCategory.hasOwnProperty("id") && (categoryTabValue !== "summary" 
                                                && categoryTabValue !== "site-condition" && categoryTabValue !== "head-count" 
                                                && categoryTabValue !== "e-catalog") && (
                                                <SubCategoryTab
                                                    hideZeroSkus={false}
                                                    subCategoryTabValue={subCategoryTabValue}
                                                    subCategories={selectedCategory?.subCategories}
                                                    categoryTabValue={categoryTabValue}
                                                    handleSubCategoryTabChange={handleSubCategoryTabChange}
                                                />
                                            )}
                                            <Box sx={{pt: 2, pb: 1}}>
                                                {subCategoryTabValue !== "" && categoryTabValue !== "summary"
                                                && categoryTabValue !== "site-condition" && categoryTabValue !== "head-count"
                                                && categoryTabValue !== "e-catalog" && (
                                                    <CustomTabPanel value={subCategoryTabValue}
                                                                    index={subCategoryTabValue}>
                                                        <EstimationItemListing
                                                            revision={currentRevision}
                                                            project={project}
                                                            subCategoryId={subCategoryTabValue}
                                                        />
                                                    </CustomTabPanel>
                                                )}

                                                {categoryTabValue === "summary" && (
                                                    <EstimationSummary
                                                        categories={categories}
                                                        revision={currentRevision}
                                                        project={project}
                                                        showUpdatedCharges={props.showUpdatedCharges}
                                                        refreshRevision={refreshRevision}
                                                    />
                                                )}

                                                {categoryTabValue === "site-condition" && (
                                                    <Box sx={{p: 2}}>
                                                        <SiteConditionTab
                                                            project={projectWithRevision}
                                                            proceedTabChange={proceedTabChange}
                                                            handleDisableNextTabs={handleDisableNextTabs}
                                                            revision={currentRevision}
                                                        />
                                                    </Box>
                                                )}
                                                {categoryTabValue === "head-count" && (
                                                <Box sx={{p: 2}}>
                                                    <HeadCountTab
                                                        project={projectWithRevision}
                                                        proceedTabChange={proceedTabChange}
                                                        handleDisableNextTabs={handleDisableNextTabs}
                                                        revision={currentRevision}
                                                    />
                                                </Box>
                                                )}

                                                {categoryTabValue === "e-catalog" && (
                                                <Box sx={{p: 2}}>
                                                    <ECatalogTab
                                                        categories={categories}
                                                        project={projectWithRevision}
                                                        proceedTabChange={proceedTabChange}
                                                        revision={currentRevision}
                                                    />
                                                </Box>
                                                )}


                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Modal>
    )
}

export default EstimationDetailsPopupNew;