import {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import {Doughnut} from "react-chartjs-2";
import {getPriceInFormatted} from "../../utils/Common";
import BoqSummarySubCategoryListing from "./BoqSummarySubCategoryListing";
import {ArcElement, Chart, Legend, Tooltip} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";

Chart.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const BoqBreakupSummary = (props: any) => {
    const [boqBreakupDetails, setBoqBreakupDetails] = useState<any[]>([]);
    const [totalEstimation, setTotalEstimation] = useState(0);
    const [chartData, setChartData] = useState<any>({});
    const backgroundColors: string[] = ["#048cdc", "#fdb67a", "#fbd474", "#e49494", "#0f8edc", "#a48cdc", "#048cee", "048cff"];
    const [mainText, setMainText] = useState("Overall");
    const [selectedCategory, setSelectedCategory] = useState<any>({});
    const [selectedSubCategory, setSelectedSubCategory] = useState("");
    const [defaultGraphSelected, setDefaultGraphSelected] = useState("boq");

    const handleGraphClick = (event: any, element: any) => {
        const label = chartData.labels[element[0].index];
        if(defaultGraphSelected == 'boq') {
            boqBreakupDetails.map((category: any) => {
                if(category.name == label) {
                    setSelectedCategory(category);
                }
            });
        } else {
            selectedCategory.subCategories.map((subCategory: any) => {
                if(subCategory.name == label) {
                    setSelectedSubCategory(subCategory);
                    handleSelectedSubCategoryGraph(subCategory.id);
                }
            });
        }
    }

    const handleSelectedSubCategory = (isSelected: boolean, subCategoryId: string) => {
        if (isSelected) {
            handleSelectedSubCategoryGraph(subCategoryId);
        } else {
            handleSelectedCategoryGraph();
        }
    }

    const handleSelectedSubCategoryGraph = (subCategoryId: string) => {
        if (subCategoryId == "") {
            handleSelectedCategoryGraph();
        } else {
            setDefaultGraphSelected("sub-category");
            selectedCategory.subCategories.map((subCategory: any) => {
                if (subCategory.id == subCategoryId) {
                    setSelectedSubCategory(subCategory);
                    const estimationItemsAllSkus: any = [];
                    subCategory.estimationItems.map((estimationItem: any) => {
                        if (estimationItem.estimationItemSkus && estimationItem.estimationItemSkus.length > 0) {
                            estimationItem.estimationItemSkus.map((sku: any) => {
                                estimationItemsAllSkus.push(sku);
                            });
                        }
                    });
                    setMainText(subCategory.name);

                    setChartData(
                        {
                            labels: estimationItemsAllSkus.map((estimationSku: any) => estimationSku.sku.name),
                            datasets: [{
                                label: '',
                                data: estimationItemsAllSkus.map((estimationSku: any) => (((estimationSku.sellingPrice * estimationSku.totalQuantity) as number) / (subCategory.total as number) * 100).toFixed(1)),
                                backgroundColor: backgroundColors,
                                borderColor: backgroundColors,
                                borderWidth: 0.2
                            }]
                        }
                    );
                }
            })
        }
    }

    const handleSelectedCategoryGraph = () => {
        if (Object.keys(selectedCategory).length > 0) {
            setDefaultGraphSelected("category");
            setMainText(selectedCategory.name);
            setChartData(
                {
                    labels: selectedCategory.subCategories.map((subCategory: any) => subCategory.name),
                    datasets: [{
                        label: '',
                        // data: selectedCategory.subCategories.map((subCategory: any) => subCategory.total),
                        data: selectedCategory.subCategories.map((subCategory: any) => ((subCategory.total as number) / (selectedCategory.total as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
        } else {
            setDefaultGraphSelected("boq");
            setMainText("Overall");
            setChartData(
                {
                    labels: boqBreakupDetails.map((category: any) => category.name),
                    datasets: [{
                        label: '',
                        data: boqBreakupDetails.map((category: any) => ((category.total as number) / (totalEstimation as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
        }
    }

    const handleCategorySelection = (category: any) => {
        if (selectedCategory.id == category.id) {
            setSelectedCategory({});
        } else {
            setSelectedCategory(category);
        }
    }

    useEffect(() => {
        handleSelectedCategoryGraph();
    }, [selectedCategory]);

    useEffect(() => {
        if (props.categories.length > 0) {
            let overAllTotal = 0;
            const updatedCategories = props.categories.map((category: any) => {
                let estimationTotal: number = 0;
                let estimationTotalCogs: number = 0;
                let estimationTotalSkus = 0;
                const subCategories = category.subCategories.map((subCategory: any) => {
                    const estimation = props.estimations?.find((estimation: any) =>
                        estimation.materialSubCategoryId === subCategory.id
                    );
                    if (estimation && estimation.estimationItems.length > 0) {
                        let subCategoryTotalCogs: number = 0;
                        let subCategoryTotal: number = 0;
                        let subCategoryTotalSkus = 0;
                        const updatedEstimations = estimation.estimationItems.map((estimationItem: any) => ({
                            ...estimationItem,
                            estimationItemSkus:
                                estimationItem.estimationItemSkus.length > 0 &&
                                estimationItem.estimationItemSkus.map((sku: any) => {
                                    subCategoryTotalCogs = subCategoryTotalCogs + (parseInt(sku.totalQuantity) * parseFloat(sku.targetPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.cogsDomainChargesPerUnit?.total));
                                    subCategoryTotal = subCategoryTotal + (parseInt(sku.totalQuantity) * parseFloat(sku.sellingPrice)) + (parseInt(sku.totalQuantity) * parseFloat(sku?.gsvDomainChargesPerUnit?.total));
                                    subCategoryTotalSkus++;
                                    return sku;
                                }),
                        }));

                        estimationTotal += subCategoryTotal;
                        estimationTotalCogs = estimationTotalCogs + subCategoryTotalCogs;
                        estimationTotalSkus += subCategoryTotalSkus;

                        return {
                            ...subCategory,
                            total: subCategoryTotal,
                            totalCogs: subCategoryTotalCogs,
                            estimationTotalSkus: subCategoryTotalSkus,
                            estimationItems: updatedEstimations,
                        };
                    }
                    return null;
                }).filter((subCategory: any) => subCategory !== null);
                subCategories.sort((a: any, b: any) => a.total - b.total);

                if (subCategories.length > 0) {
                    overAllTotal += estimationTotal;
                    return {
                        ...category,
                        total: estimationTotal,
                        totalCogs: estimationTotalCogs,
                        estimationTotalSkus,
                        subCategories,
                    };
                }

                return null;
            }).filter((category: any) => category !== null);

            setChartData(
                {
                    labels: updatedCategories.map((category: any) => category.name),
                    datasets: [{
                        data: updatedCategories.map((category: any) => ((category.total as number) / (overAllTotal as number) * 100).toFixed(1)),
                        backgroundColor: backgroundColors,
                        borderColor: backgroundColors,
                        borderWidth: 0.2
                    }]
                }
            );
            setTotalEstimation(overAllTotal);
            setBoqBreakupDetails(updatedCategories);
        }
    }, [props.estimations, props.categories]);


    return (
        <Grid container spacing={2} alignItems={"flex-start"}>
            <Grid item xs={4}>
                {Object.keys(chartData).length > 0 &&
                    <Box
                        className="chart-container"
                        sx={{
                            position: "relative",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            border: "1px solid lightgray",
                            borderRadius: "2px",
                            padding: "32px",
                            m: 0,
                            height: "95%",
                        }}
                    >
                        <Typography style={{
                            textAlign: "center",
                            position: 'absolute',
                            top: '50%',
                            left: 0,
                            right: 0,
                            marginLeft: props.mleft,
                            marginTop: props.mtop
                        }}>
                            <Typography component={"span"}
                                        sx={{
                                            width: '45%',
                                            fontSize: '20px',
                                            fontWeight: 600,
                                            display: 'inline-block',
                                            wordWrap: 'break-word'
                                        }}>
                                {mainText}
                            </Typography>
                        </Typography>
                        <Doughnut
                            id={"sm-doughnut-chart-project"}
                            data={chartData} options={
                            {
                                responsive: true,
                                cutout: "55%",
                                onClick: handleGraphClick,
                                plugins: {
                                    datalabels: {
                                        color: "#fff",
                                        font: {
                                            weight: "bold",
                                            size: 12,
                                        },
                                        formatter: (value: any, context: any) => {
                                            const index = context.dataIndex;
                                            const label = context.chart.data.datasets[0].data[index];
                                            const labelValue = parseFloat(label);
                                            if (labelValue < 3) {
                                                return "";
                                            }
                                            // return context.chart.data.labels[index] as string + " \n" + label + "%";
                                            return label + "%";
                                        },
                                        anchor: "center",
                                        align: "center",
                                        offset: -10,
                                        clip: true,
                                        display: function (context: any) {
                                            const value = context.dataset.data[context.dataIndex];
                                            return value > 2;
                                        },
                                    },
                                    legend: {
                                        display: false,
                                        position: 'right',
                                        align: 'center',
                                        labels: {
                                            usePointStyle: true
                                        }
                                    }
                                }
                            }
                        }
                        />
                    </Box>
                }
            </Grid>
            <Grid item xs={8}>
                <Box sx={{pl: 1}}>
                    <Grid container spacing={1}>
                        {boqBreakupDetails.map((boqDetails: any) => {
                            let boqCategoryTotal = boqDetails.total;
                            return (
                                <Grid item key={boqDetails.id} xs={3}
                                      onClick={() => handleCategorySelection(boqDetails)}>
                                    <Box sx={{
                                        border: 1,
                                        borderRadius: 1,
                                        borderColor: 'divider',
                                        p: 2, cursor: 'pointer',
                                        backgroundColor: selectedCategory.id == boqDetails.id ? "#E5F3FB" : "common.white"
                                    }}>
                                        <Box display={"flex"} justifyContent={"space-between"} sx={{pb: 2}}>
                                            <Typography sx={{
                                                fontSize: "13px",
                                                wordBreak: "break-word",
                                                overflowWrap: "break-word",
                                                fontWeight: 600,
                                                color: "#535983",
                                            }}>
                                                {boqDetails.name}
                                            </Typography>
                                            <Typography>
                                                {((boqDetails.total as number) / (totalEstimation as number) * 100).toFixed(1)}%
                                            </Typography>
                                        </Box>
                                        <Box display={"flex"} justifyContent={"space-between"}>
                                            <Box sx={{
                                                fontSize: "11px",
                                                wordBreak: "break-word",
                                                overflowWrap: "break-word",
                                                opacity: 0.5,
                                                width: '75%'
                                            }}>
                                                <Typography
                                                    sx={{fontSize: 'inherit', fontWeight: 600}}>Total GSV</Typography>
                                                <Typography
                                                    sx={{fontSize: 'inherit'}}>{getPriceInFormatted(boqCategoryTotal, props.project.country)}</Typography>
                                            </Box>
                                            <Box sx={{
                                                textAlign: 'right',
                                                fontSize: "11px",
                                                wordBreak: "break-word",
                                                overflowWrap: "break-word",
                                                opacity: 0.5,
                                            }}>
                                                <Typography
                                                    sx={{fontSize: 'inherit', fontWeight: 600}}>Sku(s)</Typography>
                                                <Typography
                                                    sx={{fontSize: 'inherit'}}>{boqDetails.estimationTotalSkus}</Typography>
                                            </Box>
                                            <Box></Box>
                                        </Box>
                                    </Box>
                                </Grid>
                            )
                        })}
                    </Grid>
                    {Object.keys(selectedCategory).length > 0 &&
                        <BoqSummarySubCategoryListing category={selectedCategory} subCategory={selectedSubCategory} project={props.project}
                                                      showUpdatedCharges={props.showUpdatedCharges}
                                                      handleSelectedSubCategory={handleSelectedSubCategory}/>
                    }
                </Box>
            </Grid>
        </Grid>
    )
}

export default BoqBreakupSummary;