import {Typography} from "@mui/material";
import { getFormattedDate, getPriceInFormatted } from "../../../../utils/Common";

export const eventConfig = [
    {field: 'id', headerName: '#', headerClassName: 'data-grid-header', sortable: false, flex: 1},
    {
        field: 'projectName',
        headerName: 'Project Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => params.row?.project?.name
    },
    {
        field: 'packageName',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => params.row?.eventPackages[0]?.name + (params.row?.eventPackages.length > 1 ? " and Many" : "")
    },
    {
        field: 'createAt', headerName: 'Created On', type: 'date', headerClassName: 'data-grid-header', flex: 1,
        valueGetter: (params: any) => new Date(params.row?.createdAt)
    },
    {
        field: 'startDate', headerName: 'Start Date', type: 'date', headerClassName: 'data-grid-header', flex: 1,
        valueGetter: (params: any) => new Date(params.row?.startDate)
    },
    {
        field: 'endDate', headerName: 'End Date', type: 'date', headerClassName: 'data-grid-header', flex: 1,
        valueGetter: (params: any) => new Date(params.row?.endDate)
    },
    {
        field: 'status', headerName: 'Status', headerClassName: 'data-grid-header', flex: 1,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name == 'CREATED') {
                color = '223, 149, 113';
            }
            if (name == 'CLOSED') {
                color = '223, 112, 113';
            }

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    borderRadius: 1,
                    p: 0.6,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        show: 'button',
        buttons: [{
            type: 'detail',
            title: 'View Details',
            permissions: ['vendor-admin'],
            condition: (row: any) => true
        }]
    },
]


export const packageConfig = [
    {field: 'id', headerName: '#', headerClassName: 'data-grid-header', sortable: false, flex: 1},
    {
        field: 'packageName',
        headerName: 'Package Name',
        headerClassName: 'data-grid-header',
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => {
            console.log('params', params)
            return params.row.name
        }
    },
    {
        field: 'createAt', headerName: 'Created On', type: 'date', headerClassName: 'data-grid-header', flex: 1,
        valueGetter: (params: any) => {
            console.log("params", params)
            return new Date(params.row?.createdAt)
        }
    },
    {
        field: 'isEvent', headerName: 'Is Event?', headerClassName: 'data-grid-header', flex: 1, sortable: true,
        valueGetter: (params: any) => {
            return params.row?.event ? 'Yes' : 'No'
        }
    },
    {
        field: 'status', headerName: 'Status', headerClassName: 'data-grid-header', flex: 1,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name === 'CREATED') {
                color = '223, 149, 113';
            }
            if (name === 'CLOSED') {
                color = '223, 112, 113';
            }

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    borderRadius: 1,
                    p: 0.6,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase() === 'pr' ? name.toUpperCase(): name.toLowerCase()}</Typography>
            )
        }
    },
    {
        field: 'total', headerName: 'Total', headerClassName: 'data-grid-header', flex: 1, sortable: true,
        valueGetter: (params: any) => {
            return params.row?.total
        }
    },
    {
        field: 'action',
        headerName: 'Action',
        headerClassName: 'data-grid-header',
        flex: 1,
        sortable: false,
        show: 'button',
        buttons: [{
            type: 'packageDetail',
            title: 'View Details',
            permissions: ['vendor-admin'],
            condition: (row: any) => true
        }]
    },
]
export const packageDetail =  [
    {
        field: "name",
        headerName: "Package Name",
        headerClassName: "data-grid-header",
        sortable: false,
        flex: 1,
        maxWidth: 200,
    },
    {
        field: "vendorLink",
        headerName: "Vendor/Brand",
        headerClassName: "data-grid-header",
        sortable: false,
        flex: 1,
        maxWidth: 200,
        renderCell: (params: any) => {
            const vendor = params.row.vendor;
            const brand = params.row.brand;

            return (
                vendor != null ?
                    <a className={"break-word-anchor"}
                       href={`/vendors/details/${vendor?.id}`}
                       rel="noreferrer"
                       target={"_blank"}
                    >
                        {vendor.name}
                    </a>
                    : brand != null ? <a
                        href={`/brnad/details/${brand?.id}`}
                        rel="noreferrer"
                        target={"_blank"}
                    >
                        {brand.name}
                    </a> : "NA"
            )
        }
    },
    {
        field: "createdAt",
        headerName: "Created On",
        headerClassName: "data-grid-header",
        sortable: false,
        flex: 1,
        valueGetter: (params: any) => getFormattedDate(params.row?.createdAt),
    },
    {
        field: "total",
        headerName: "Total Value",
        type: "price",
        headerClassName: "data-grid-header",
        sortable: false,
        flex: 1,
    },
    {
        field: "status",
        headerName: "Status",
        headerClassName: "data-grid-header",
        sortable: false,
        flex: 1,
        renderCell: (params: any) => {
            let color = '253, 182, 122';
            let name = params.row.status;
            if (name == 'CREATED') {
                color = '223, 149, 113';
            }
            if (name == 'PR') {
                color = '223, 112, 113';
            }
            if (name == 'EVENT') {
                color = '223, 149, 149';
            }
            if (name == 'CLOSED') {
                color = '55, 214, 112';
            }

            return (
                <Typography sx={{
                    textTransform: 'capitalize',
                    border: 1,
                    borderColor: `rgba(${color}, 1)`,
                    borderRadius: 1,
                    p: 0.6,
                    backgroundColor: `rgba(${color}, 0.5)`,
                    minWidth: '100px',
                    textAlign: 'left'
                }}>{name.toLowerCase()}</Typography>
            )
        }
    },
]

export const packageDetails =  [
    {
        field: 'id',
        headerName: 'Sr. No.',
        sortable: false,
        filterable: false,
        renderCell: (params: any) =>
            params.api.getRowIndexRelativeToVisibleRows(params.row.id) + 1
    },
    {
        field: "itemCategory",
        headerName: "Item Category",
        sortable: false,
        headerClassName: "data-grid-header",
        flex: 1,
        valueGetter: (params: any) => params.row?.itemDetail?.itemCategory?.name + "-" + params.row?.itemDetail?.item?.name
    },
    {
        field: "selectedItem",
        headerName: "Selected Item",
        sortable: false,
        headerClassName: "data-grid-header",
        flex: 1,
        renderCell: (params: any) => (
            <a
                href={`/catalogue/skus/details/${params.row?.itemDetail?.sku?.id}`}
                rel="noreferrer"
                target={"_blank"}
            >
                {params.row?.itemDetail?.sku?.name}
            </a>
        ),
    },
    {
        field: "uom",
        headerName: "UOM",
        sortable: false,
        headerClassName: "data-grid-header",
        flex: 1,
        valueGetter: (params: any) => params.row?.itemDetail?.sku?.uom
    },
    {
        field: "totalQuantity",
        headerName: "Quantity",
        sortable: false,
        headerClassName: "data-grid-header",
        flex: 1
    },
    {
        field: "ratePerUom",
        headerName: "Rate per UOM",
        sortable: false,
        headerClassName: "data-grid-header",
        flex: 1,
        valueGetter: (params: any) => getPriceInFormatted(params.row?.cogsPrice > 0 ? params.row?.cogsPrice : 0, "")
    },
]

